const vidyoConnectorDisconnectReason = {
    'VIDYO_CONNECTORDISCONNECTREASON_Disconnected': 'VIDYO_CONNECTORDISCONNECTREASON_Disconnected', //'The user disconnected gracefully.',
    'VIDYO_CONNECTORDISCONNECTREASON_ConnectionLost': 'VIDYO_CONNECTORDISCONNECTREASON_ConnectionLost', //'The transport connection was lost.',
    'VIDYO_CONNECTORDISCONNECTREASON_ConnectionTimeout': 'VIDYO_CONNECTORDISCONNECTREASON_ConnectionTimeout', //'The signaling connection timed-out.',
    'VIDYO_CONNECTORDISCONNECTREASON_NoResponse': 'VIDYO_CONNECTORDISCONNECTREASON_NoResponse', //'The service did not respond in a reasonable amount of time to a request by the user.',
    'VIDYO_CONNECTORDISCONNECTREASON_Terminated': 'VIDYO_CONNECTORDISCONNECTREASON_Terminated', //'The service closed the connection or otherwise terminated the login session.',
    'VIDYO_CONNECTORDISCONNECTREASON_MiscLocalError': 'VIDYO_CONNECTORDISCONNECTREASON_MiscLocalError', //'A miscellaneous problem occurred with this client library.',
    'VIDYO_CONNECTORDISCONNECTREASON_MiscRemoteError': 'VIDYO_CONNECTORDISCONNECTREASON_MiscRemoteError', //'A miscellaneous problem occurred with the conferencing service.',
    'VIDYO_CONNECTORDISCONNECTREASON_MiscError': 'VIDYO_CONNECTORDISCONNECTREASON_MiscError', //'The connection was closed for some other miscellaneous reason.'
}
const vidyoConnectorDisconnectReasonDescription = {
    'VIDYO_CONNECTORDISCONNECTREASON_Disconnected': 'The user disconnected gracefully.',
    'VIDYO_CONNECTORDISCONNECTREASON_ConnectionLost': 'The transport connection was lost.',
    'VIDYO_CONNECTORDISCONNECTREASON_ConnectionTimeout': 'The signaling connection timed-out.',
    'VIDYO_CONNECTORDISCONNECTREASON_NoResponse': 'The service did not respond in a reasonable amount of time to a request by the user.',
    'VIDYO_CONNECTORDISCONNECTREASON_Terminated': 'The service closed the connection or otherwise terminated the login session.',
    'VIDYO_CONNECTORDISCONNECTREASON_MiscLocalError': 'A miscellaneous problem occurred with this client library.',
    'VIDYO_CONNECTORDISCONNECTREASON_MiscRemoteError': 'A miscellaneous problem occurred with the conferencing service.',
    'VIDYO_CONNECTORDISCONNECTREASON_MiscError': 'The connection was closed for some other miscellaneous reason.'
}
const vidyoConnectorMode = {
    'VIDYO_CONNECTORMODE_Default': 'VIDYO_CONNECTORMODE_Default', //'The default operating mode.',
    'VIDYO_CONNECTORMODE_Foreground': 'VIDYO_CONNECTORMODE_Foreground', //'Runs in the foreground.',
    'VIDYO_CONNECTORMODE_Background': 'VIDYO_CONNECTORMODE_Background', //'Runs in the background.'
}
const vidyoConnectorModeDescription = {
    'VIDYO_CONNECTORMODE_Default': 'The default operating mode.',
    'VIDYO_CONNECTORMODE_Foreground': 'Runs in the foreground.',
    'VIDYO_CONNECTORMODE_Background': 'Runs in the background.'
}
const vidyoConnectorState = {
    'Ready': 'Ready',
    'VIDYO_CONNECTORSTATE_Idle': 'VIDYO_CONNECTORSTATE_Idle', //'Not ready to connect, no devices.',
    'VIDYO_CONNECTORSTATE_Ready': 'VIDYO_CONNECTORSTATE_Ready', //'Ready to connect.',
    'VIDYO_CONNECTORSTATE_EstablishingConnection': 'VIDYO_CONNECTORSTATE_EstablishingConnection', //'Establishing a connection to the server.',
    'VIDYO_CONNECTORSTATE_FindingResource': 'VIDYO_CONNECTORSTATE_FindingResource', // 'Finding a resource.',
    'VIDYO_CONNECTORSTATE_ConnectingToResource': 'VIDYO_CONNECTORSTATE_ConnectingToResource', //'Connecting to a resource.',
    'VIDYO_CONNECTORSTATE_EnablingMedia': 'VIDYO_CONNECTORSTATE_EnablingMedia', //'Enabling media.',
    'VIDYO_CONNECTORSTATE_Connected': 'VIDYO_CONNECTORSTATE_Connected', //'Successfully connected.'
}
const vidyoConnectorStateDescription = {
    'VIDYO_CONNECTORSTATE_Idle': 'Not ready to connect, no devices.',
    'VIDYO_CONNECTORSTATE_Ready': 'Ready to connect.',
    'VIDYO_CONNECTORSTATE_EstablishingConnection': 'Establishing a connection to the server.',
    'VIDYO_CONNECTORSTATE_FindingResource': 'Finding a resource.',
    'VIDYO_CONNECTORSTATE_ConnectingToResource': 'Connecting to a resource.',
    'VIDYO_CONNECTORSTATE_EnablingMedia': 'Enabling media.',
    'VIDYO_CONNECTORSTATE_Connected': 'Successfully connected.'
}
const vidyoConnectorFailReason = {
    'VIDYO_CONNECTORFAILREASON_ConnectionFailed': 'VIDYO_CONNECTORFAILREASON_ConnectionFailed', //'The destination could not be reached.',
    'VIDYO_CONNECTORFAILREASON_ConnectionLost': 'VIDYO_CONNECTORFAILREASON_ConnectionLost', //'The transport connection was lost prior to completing the login procedure.',
    'VIDYO_CONNECTORFAILREASON_ConnectionTimeout': 'VIDYO_CONNECTORFAILREASON_ConnectionTimeout', //'The signaling connection timed-out prior to completing the login procedure.',
    'VIDYO_CONNECTORFAILREASON_NoResponse': 'VIDYO_CONNECTORFAILREASON_NoResponse', //'The service was successfully contacted, but the service ignored the user\'s request to log in or it did not answer in a reasonable amount of time.',
    'VIDYO_CONNECTORFAILREASON_Terminated': 'VIDYO_CONNECTORFAILREASON_Terminated', //'The service was successfully contacted, but the service closed the connection or it refused to continue processing the login request.',
    'VIDYO_CONNECTORFAILREASON_InvalidToken': 'VIDYO_CONNECTORFAILREASON_InvalidToken', //'The token that was provided is unknown to the service or unauthorized to log in.',
    'VIDYO_CONNECTORFAILREASON_UnableToCreateResource': 'VIDYO_CONNECTORFAILREASON_UnableToCreateResource', //'The server was unable to create the resource.',
    'VIDYO_CONNECTORFAILREASON_NoResponseFromResource': 'VIDYO_CONNECTORFAILREASON_NoResponseFromResource', //'The server did not respond in a reasonable amount of time to the request to connect to the resource.',
    'VIDYO_CONNECTORFAILREASON_InvalidResourceId': 'VIDYO_CONNECTORFAILREASON_InvalidResourceId', //'The resource ID was invalid.',
    'VIDYO_CONNECTORFAILREASON_ResourceFull': 'VIDYO_CONNECTORFAILREASON_ResourceFull', //'The resource is at full capacity, and it cannot accept additional members.',
    'VIDYO_CONNECTORFAILREASON_NotMember': 'VIDYO_CONNECTORFAILREASON_NotMember', //'The resource allows only certain users to enter, and the current user is not one of them.',
    'VIDYO_CONNECTORFAILREASON_Banned': 'VIDYO_CONNECTORFAILREASON_Banned', //'The user has been banned from entering the resource.',
    'VIDYO_CONNECTORFAILREASON_MediaNotEnabled': 'VIDYO_CONNECTORFAILREASON_MediaNotEnabled', //'The client could not enable media.',
    'VIDYO_CONNECTORFAILREASON_MediaFailed': 'VIDYO_CONNECTORFAILREASON_MediaFailed', //'An established media connection failed.',
    'VIDYO_CONNECTORFAILREASON_MiscLocalError': 'VIDYO_CONNECTORFAILREASON_MiscLocalError', //'The server rejected the user\'s request to enter the resource, due to a miscellaneous problem with the request.',
    'VIDYO_CONNECTORFAILREASON_MiscRemoteError': 'VIDYO_CONNECTORFAILREASON_MiscRemoteError', //'The server rejected the user\'s request to enter the resource, due to a miscellaneous problem of its own.',
    'VIDYO_CONNECTORFAILREASON_MiscError': 'VIDYO_CONNECTORFAILREASON_MiscError', //'The login failed for some other miscellaneous reason.',
    'VIDYO_CONNECTORFAILREASON_InvalidDisplayNameLength': 'VIDYO_CONNECTORFAILREASON_InvalidDisplayNameLength', //'Length of the display name is longer than 256 characters.'
    'VIDYO_CONNECTORFAILREASON_BrowserVersionNotSupported': 'VIDYO_CONNECTORFAILREASON_BrowserVersionNotSupported',
    'VIDYO_CONNECTORFAILREASON_BrowserOffLine': 'VIDYO_CONNECTORFAILREASON_BrowserOffLine',
    'VIDYO_CONNECTORFAILREASON_NotSupported': 'VIDYO_CONNECTORFAILREASON_NotSupported',
    'VIDYO_CONNECTORFAILREASON_RoomLocked': 'VIDYO_CONNECTORFAILREASON_RoomLocked',
    'VIDYO_CONNECTORFAILREASON_RoomDisabled': 'VIDYO_CONNECTORFAILREASON_RoomDisabled',
    'VIDYO_CONNECTORFAILREASON_AllLinesInUse': 'VIDYO_CONNECTORFAILREASON_AllLinesInUse',
    'VIDYO_CONNECTORFAILREASON_SeatLicenseExpired': 'VIDYO_CONNECTORFAILREASON_SeatLicenseExpired',
    'VIDYO_CONNECTORFAILREASON_NotLicensed': 'VIDYO_CONNECTORFAILREASON_NotLicensed',
    'VIDYO_CONNECTORFAILREASON_Rejected': 'VIDYO_CONNECTORFAILREASON_Rejected'
}
const vidyoConnectorFailReasonDescription = {
    'VIDYO_CONNECTORFAILREASON_ConnectionFailed': 'The destination could not be reached.',
    'VIDYO_CONNECTORFAILREASON_ConnectionLost': 'The transport connection was lost prior to completing the login procedure.',
    'VIDYO_CONNECTORFAILREASON_ConnectionTimeout': 'The signaling connection timed-out prior to completing the login procedure.',
    'VIDYO_CONNECTORFAILREASON_NoResponse': 'The service was successfully contacted, but the service ignored the user\'s request to log in or it did not answer in a reasonable amount of time.',
    'VIDYO_CONNECTORFAILREASON_Terminated': 'The service was successfully contacted, but the service closed the connection or it refused to continue processing the login request.',
    'VIDYO_CONNECTORFAILREASON_InvalidToken': 'The token that was provided is unknown to the service or unauthorized to log in.',
    'VIDYO_CONNECTORFAILREASON_UnableToCreateResource': 'The server was unable to create the resource.',
    'VIDYO_CONNECTORFAILREASON_NoResponseFromResource': 'The server did not respond in a reasonable amount of time to the request to connect to the resource.',
    'VIDYO_CONNECTORFAILREASON_InvalidResourceId': 'The resource ID was invalid.',
    'VIDYO_CONNECTORFAILREASON_ResourceFull': 'The resource is at full capacity, and it cannot accept additional members.',
    'VIDYO_CONNECTORFAILREASON_NotMember': 'The resource allows only certain users to enter, and the current user is not one of them.',
    'VIDYO_CONNECTORFAILREASON_Banned': 'The user has been banned from entering the resource.',
    'VIDYO_CONNECTORFAILREASON_MediaNotEnabled': 'The client could not enable media.',
    'VIDYO_CONNECTORFAILREASON_MediaFailed': 'An established media connection failed.',
    'VIDYO_CONNECTORFAILREASON_MiscLocalError': 'The server rejected the user\'s request to enter the resource, due to a miscellaneous problem with the request.',
    'VIDYO_CONNECTORFAILREASON_MiscRemoteError': 'The server rejected the user\'s request to enter the resource, due to a miscellaneous problem of its own.',
    'VIDYO_CONNECTORFAILREASON_MiscError': 'The login failed for some other miscellaneous reason.',
    'VIDYO_CONNECTORFAILREASON_InvalidDisplayNameLength': 'Length of the display name is longer than 256 characters.',
    'VIDYO_CONNECTORFAILREASON_BrowserVersionNotSupported': 'VIDYO_CONNECTORFAILREASON_BrowserVersionNotSupported',
    'VIDYO_CONNECTORFAILREASON_BrowserOffLine': 'VIDYO_CONNECTORFAILREASON_BrowserOffLine',
    'VIDYO_CONNECTORFAILREASON_NotSupported': 'VIDYO_CONNECTORFAILREASON_NotSupported',
    'VIDYO_CONNECTORFAILREASON_RoomLocked': 'VIDYO_CONNECTORFAILREASON_RoomLocked',
    'VIDYO_CONNECTORFAILREASON_RoomDisabled': 'VIDYO_CONNECTORFAILREASON_RoomDisabled',
    'VIDYO_CONNECTORFAILREASON_AllLinesInUse': 'VIDYO_CONNECTORFAILREASON_AllLinesInUse',
    'VIDYO_CONNECTORFAILREASON_SeatLicenseExpired': 'VIDYO_CONNECTORFAILREASON_SeatLicenseExpired',
    'VIDYO_CONNECTORFAILREASON_NotLicensed': 'VIDYO_CONNECTORFAILREASON_NotLicensed',
    'VIDYO_CONNECTORFAILREASON_Rejected': 'VIDYO_CONNECTORFAILREASON_Rejected'
}
const vidyoDeviceState = {
    'VIDYO_DEVICESTATE_Added': 'VIDYO_DEVICESTATE_Added', //'The device was added to the system.',
    'VIDYO_DEVICESTATE_Removed': 'VIDYO_DEVICESTATE_Removed', //'The device was removed from the system.',
    'VIDYO_DEVICESTATE_Started': 'VIDYO_DEVICESTATE_Started', //'The device started successfully.',
    'VIDYO_DEVICESTATE_Stopped': 'VIDYO_DEVICESTATE_Stopped', //'The device stopped.',
    'VIDYO_DEVICESTATE_Suspended': 'VIDYO_DEVICESTATE_Suspended', //'The device was suspended and the frames can no longer be captured.',
    'VIDYO_DEVICESTATE_Unsuspended': 'VIDYO_DEVICESTATE_Unsuspended', //'The device was unsuspended.',
    'VIDYO_DEVICESTATE_InUse': 'VIDYO_DEVICESTATE_InUse', //'The device is in use by another application.',
    'VIDYO_DEVICESTATE_Available': 'VIDYO_DEVICESTATE_Available', //'The device that was previousely in use is now available.',
    'VIDYO_DEVICESTATE_Paused': 'VIDYO_DEVICESTATE_Paused', //'The device was paused (muted) and no media will be sent.',
    'VIDYO_DEVICESTATE_ControlledByRemoteEntity': 'VIDYO_DEVICESTATE_ControlledByRemoteEntity', //'The device is under Remote control (i.e if paused, can only be resumed by remote controller.',
    'VIDYO_DEVICESTATE_NotControlledByRemoteEntity': 'VIDYO_DEVICESTATE_NotControlledByRemoteEntity', //'The device is not under Remote control anymore.',
    'VIDYO_DEVICESTATE_Resumed': 'VIDYO_DEVICESTATE_Resumed', //'The device was resumed (unmuted) and media will be sent.',
    'VIDYO_DEVICESTATE_Controllable': 'VIDYO_DEVICESTATE_Controllable', //'The device PTZ is controllable.',
    'VIDYO_DEVICESTATE_NotControllable': 'VIDYO_DEVICESTATE_NotControllable', //'The device PTZ is not controllable.',
    'VIDYO_DEVICESTATE_DefaultChanged': 'VIDYO_DEVICESTATE_DefaultChanged', //'The device default device has changed.',
    'VIDYO_DEVICESTATE_ConfigureSuccess': 'VIDYO_DEVICESTATE_ConfigureSuccess', //'The device configured successfully.',
    'VIDYO_DEVICESTATE_ConfigureError': 'VIDYO_DEVICESTATE_ConfigureError', //'The device configuring failed.',
    'VIDYO_DEVICESTATE_ConfigurationChanged': 'VIDYO_DEVICESTATE_ConfigurationChanged', //'The device configuring changed.',
    'VIDYO_DEVICESTATE_Error': 'VIDYO_DEVICESTATE_Error', //'The device failed.'
}

const vidyoDeviceStateDescription = {
    'VIDYO_DEVICESTATE_Added': 'The device was added to the system.',
    'VIDYO_DEVICESTATE_Removed': 'The device was removed from the system.',
    'VIDYO_DEVICESTATE_Started': 'The device started successfully.',
    'VIDYO_DEVICESTATE_Stopped': 'The device stopped.',
    'VIDYO_DEVICESTATE_Suspended': 'The device was suspended and the frames can no longer be captured.',
    'VIDYO_DEVICESTATE_Unsuspended': 'The device was unsuspended.',
    'VIDYO_DEVICESTATE_InUse': 'The device is in use by another application.',
    'VIDYO_DEVICESTATE_Available': 'The device that was previously in use is now available.',
    'VIDYO_DEVICESTATE_Paused': 'The device was paused (muted) and no media will be sent.',
    'VIDYO_DEVICESTATE_ControlledByRemoteEntity': 'The device is under Remote control (i.e if paused, can only be resumed by remote controller.',
    'VIDYO_DEVICESTATE_NotControlledByRemoteEntity': 'The device is not under Remote control anymore.',
    'VIDYO_DEVICESTATE_Resumed': 'The device was resumed (unmuted) and media will be sent.',
    'VIDYO_DEVICESTATE_Controllable': 'The device PTZ is controllable.',
    'VIDYO_DEVICESTATE_NotControllable': 'The device PTZ is not controllable.',
    'VIDYO_DEVICESTATE_DefaultChanged': 'The device default device has changed.',
    'VIDYO_DEVICESTATE_ConfigureSuccess': 'The device configured successfully.',
    'VIDYO_DEVICESTATE_ConfigureError': 'The device configuring failed.',
    'VIDYO_DEVICESTATE_ConfigurationChanged': 'The device configuring changed.',
    'VIDYO_DEVICESTATE_Error': 'The device failed.'
}
const vidyoDeviceType = {
    'VIDYO_DEVICETYPE_LocalCamera': 'VIDYO_DEVICETYPE_LocalCamera', //'local camera device.',
    'VIDYO_DEVICETYPE_LocalMicrophone': 'VIDYO_DEVICETYPE_LocalMicrophone', //'local microphone device.',
    'VIDYO_DEVICETYPE_LocalMonitor': 'VIDYO_DEVICETYPE_LocalMonitor', //'local screen device.',
    'VIDYO_DEVICETYPE_LocalWindowShare': 'VIDYO_DEVICETYPE_LocalWindowShare', //'local application window device.',
    'VIDYO_DEVICETYPE_LocalSpeaker': 'VIDYO_DEVICETYPE_LocalSpeaker', //'local speaker device.',
    'VIDYO_DEVICETYPE_LocalRenderer': 'VIDYO_DEVICETYPE_LocalRenderer', //'local renderer device.',
    'VIDYO_DEVICETYPE_RemoteCamera': 'VIDYO_DEVICETYPE_RemoteCamera', //'remote camera device.',
    'VIDYO_DEVICETYPE_RemoteMicrophone': 'VIDYO_DEVICETYPE_RemoteMicrophone', //'remote microphone device.',
    'VIDYO_DEVICETYPE_RemoteWindowShare': 'VIDYO_DEVICETYPE_RemoteWindowShare', //'remote application window device.',
    'VIDYO_DEVICETYPE_RemoteSpeaker': 'VIDYO_DEVICETYPE_RemoteSpeaker', //'remote speaker device.',
    'VIDYO_DEVICETYPE_RemoteRenderer': 'VIDYO_DEVICETYPE_RemoteRenderer', //'remote renderer device.',
    'VIDYO_DEVICETYPE_VirtualVideoSource': 'VIDYO_DEVICETYPE_VirtualVideoSource', //'virtual video source.'
}
const vidyoDeviceTypeDescription = {
    'VIDYO_DEVICETYPE_LocalCamera': 'local camera device.',
    'VIDYO_DEVICETYPE_LocalMicrophone': 'local microphone device.',
    'VIDYO_DEVICETYPE_LocalMonitor': 'local screen device.',
    'VIDYO_DEVICETYPE_LocalWindowShare': 'local application window device.',
    'VIDYO_DEVICETYPE_LocalSpeaker': 'local speaker device.',
    'VIDYO_DEVICETYPE_LocalRenderer': 'local renderer device.',
    'VIDYO_DEVICETYPE_RemoteCamera': 'remote camera device.',
    'VIDYO_DEVICETYPE_RemoteMicrophone': 'remote microphone device.',
    'VIDYO_DEVICETYPE_RemoteWindowShare': 'remote application window device.',
    'VIDYO_DEVICETYPE_RemoteSpeaker': 'remote speaker device.',
    'VIDYO_DEVICETYPE_RemoteRenderer': 'remote renderer device.',
    'VIDYO_DEVICETYPE_VirtualVideoSource': 'virtual video source.'
}

const vidyoDeiceAudioSignalType = {
    'VIDYO_DEVICEAUDIOSIGNALTYPE_Voice': 'VIDYO_DEVICEAUDIOSIGNALTYPE_Voice', //'Default type which will do echo cancellation, noise suppresstion etc.',
    'VIDYO_DEVICEAUDIOSIGNALTYPE_Unprocessed': 'VIDYO_DEVICEAUDIOSIGNALTYPE_Unprocessed', //'Does not alter audio singal by having all the audio processing disabled.'
}
const vidyoDeiceAudioSignalTypeDescription = {
    'VIDYO_DEVICEAUDIOSIGNALTYPE_Voice': 'Default type which will do echo cancellation, noise suppresstion etc.',
    'VIDYO_DEVICEAUDIOSIGNALTYPE_Unprocessed': 'Does not alter audio singal by having all the audio processing disabled.'
}
export {
    vidyoDeviceState,
    vidyoDeviceStateDescription,
    vidyoDeviceType,
    vidyoDeviceTypeDescription,
    vidyoDeiceAudioSignalType,
    vidyoDeiceAudioSignalTypeDescription,
    vidyoConnectorState,
    vidyoConnectorStateDescription,
    vidyoConnectorFailReason,
    vidyoConnectorFailReasonDescription,
    vidyoConnectorDisconnectReason,
    vidyoConnectorDisconnectReasonDescription,
    vidyoConnectorMode,
    vidyoConnectorModeDescription,
}
