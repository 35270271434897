// import {ApiJsonResponse, ApplicationType} from "../../Types";
import {createAppSlice} from "../../State/createAppSlice.ts";
// import Logger from "../../Utils/Logger.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {VidyoSliceState, VidyoType} from "../../Types/VidyoTypes.ts";
import Logger from "../../Utils/Logger.ts";

export const initialState: VidyoSliceState = {
    isLoading: false,
    error: '',
    data: undefined,
}

export const vidyoSlice = createAppSlice({
    name: 'vidyo',
    initialState,
    reducers: (create) => ({
        save: create.reducer((state, action: PayloadAction<VidyoType>) => {
            Logger.warn("SAVE", action.payload)
            state.data = {
                ...action.payload
            };
        }),
        reset: create.reducer((state) => {
            state.isLoading = false;
            state.error = '';
            state.data = undefined
        }),
    }),
    selectors: {
        getVidyo: vidyoState => vidyoState.data,
    }
})

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
    save,
    reset,
} = vidyoSlice.actions
export const {
    getVidyo
} = vidyoSlice.selectors
// Export the slice reducer as the default export
// export default userSlice.reducer
