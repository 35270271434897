import {StyleSheet} from "aphrodite";
import theme from '../themes/themeConstants.ts'

const styles = StyleSheet.create({
    pageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // flexGrow: 1,
        height: 'calc(100% - 80px)',

        padding: 10,
    },
    container: {
        width: "100%",
        maxWidth: 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    newApplicationContainer: {
        height: 100,
        width: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    applicationsContainer: {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // borderStyle: 'solid',
        // borderWidth: 1,
        // borderColor: theme.colors.lightGrey,
        borderRadius: 4,
        padding: 20,
        // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        // boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
        // backgroundColor: theme.colors.offWhite,
    },
    newApplicationButton: {
        height: 50,
    },
    tooltip: {
        backgroundColor: theme.colors.lightGrey,
        color: theme.colors.primary,
    },
    border: {
        height: 3,
        width: '100%',
        backgroundColor: theme.colors.primary
    },
    applicationListContainer: {
        padding: "0px 20px",
    }
});
export default styles;
