import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import useTranslate from "../../../Hooks/useTranslate.ts";
import {useState} from "react";
import Form from "react-bootstrap/Form";
import {usePinInput} from 'react-pin-input-hook'
import {css} from "aphrodite";
import styles from "./styles.ts";

interface OtpModalProps {
    show: boolean;
    onHide?: () => void;
    onCancel?: () => void;
    onConfirm?: (otp: string) => void;
}

const OtpModal = (props: OtpModalProps) => {
    const {show, onConfirm, onCancel, onHide} = props;
    const [values, setValues] = useState<string[]>(Array(6).fill(''));
    const {t} = useTranslate('translations');
    const handleConfirm = () => {
        if (onConfirm && typeof onConfirm === 'function') {
            if (!values.includes("")) {
                onConfirm(values.join(''))
            }
        }
    }
    const handleCancel = () => {
        if (onCancel && typeof onCancel === 'function') {
            onCancel()
        }
    }
    const {fields} = usePinInput({
        values,
        placeholder: "",
        onChange: (values) => {
            setValues(values)
        },
    })
    return (
        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={'static'}
            centered
        >
            <Modal.Header closeButton={!!onHide}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('Enter OTP')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className={"d-flex justify-content-center align-items-center"}>
                        <div className={css(styles.pinInputContainer)}>
                            {fields.map((propsField, index) => (
                                <Form.Control key={index}
                                              className={css(styles.pinInputField)} {...propsField} />
                            ))}
                        </div>
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                {onCancel && <Button variant={"secondary"} onClick={handleCancel}>{t('Close')}</Button>}
                {onConfirm && <Button onClick={handleConfirm}>{t('Submit')}</Button>}
            </Modal.Footer>
        </Modal>
    );
}
export {OtpModal}
export type {OtpModalProps}
