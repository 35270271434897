import {RootState, store} from "../../State/store.ts";
import {createSelector} from "@reduxjs/toolkit";
import {setApplicationId, setShowOtpModal, setShowVideoSettingsModal} from "./navigatorSlice.ts";

class Navigator {

    /** ACTIONS **/
    static setApplicationId = (id?: string) => {
        return store.dispatch(setApplicationId(id));
    };
    static setShowOtpModal = (show: boolean) => {
        return store.dispatch(setShowOtpModal(show));
    };
    static setShowVideoSettingsModal = (show: boolean) => {
        return store.dispatch(setShowVideoSettingsModal(show));
    };

    /** SELECTORS **/
    static redirectToVideoLobby = createSelector([(state: RootState) => state.navigator], (navigatorState) => {
        return !!navigatorState.applicationId;
    })
    static selectApplicationId = createSelector([(state: RootState) => state.navigator], (navigatorState) => {
        return navigatorState.applicationId;
    })
    static selectShowOtpModal = createSelector([(state: RootState) => state.navigator], (navigatorState) => {
        return navigatorState.showOtpModal;
    })
    static selectShowVideoSettingsModal = createSelector([(state: RootState) => state.navigator], (navigatorState) => {
        return navigatorState.showVideoSettingsModal;
    })
}

export default Navigator;
