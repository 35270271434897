import {css} from "aphrodite";
import styles from "./styles.js";
import Form from "react-bootstrap/Form";
import Vidyo from "../../../Models/Vidyo/Vidyo.ts";
import {SpeakerSelectProps} from "./SpeakerSelectProps.ts";

const SpeakerSelect = (props: SpeakerSelectProps) => {
    const {speakers, selectedSpeakerId, selectSpeakerById, disabled} = props
    if (speakers) {
        return (
            <Form.Group controlId={'speakerIdLabel'} className={css(styles.deviceSelect)}>
                <Form.Label>Speaker</Form.Label>
                <Form.Select name="speakerId" id={"speakerId"} size={"lg"}
                             value={selectedSpeakerId || ''} onChange={selectSpeakerById}
                             disabled={disabled}>
                    {Object.keys(speakers)?.map(speakerId =>
                        <option value={speakerId} key={speakerId}>
                            {Vidyo.formatDeviceName(speakers[speakerId]?.name)}
                        </option>
                    )}
                </Form.Select>
            </Form.Group>
        )
    }
    return null;
}
export default SpeakerSelect
