import {createAppSlice} from "../../State/createAppSlice.ts";
import {NavigatorSliceState} from "../../Types/NavigatorTypes";
import {PayloadAction} from "@reduxjs/toolkit";

const initialState: NavigatorSliceState = {
    applicationId: undefined,
    showOtpModal: false,
    showVideoSettingsModal: false,
};

export const navigatorSlice = createAppSlice({
    name: "navigator",
    initialState,
    reducers: (create) => ({
        setApplicationId: create.reducer(
            (state, action: PayloadAction<string | undefined>) => {
                console.log("navigatorSlice@setApplicationId", action.payload);
                state.applicationId = action.payload;
            }
        ),
        setShowOtpModal: create.reducer(
            (state, action: PayloadAction<boolean>) => {
                state.showOtpModal = action.payload;
            }
        ),
        setShowVideoSettingsModal: create.reducer(
            (state, action: PayloadAction<boolean>) => {
                state.showVideoSettingsModal = action.payload;
            }
        ),
    }),
    selectors: {},
});

export const {setApplicationId, setShowOtpModal, setShowVideoSettingsModal} = navigatorSlice.actions;
export const {} = navigatorSlice.selectors;
