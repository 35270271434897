import ProfileIcon from "../../../Icons/user-circle-svgrepo-.svg";
import {Dropdown} from "react-bootstrap";
import User from "../../../../../Models/User/User";
import {css} from "aphrodite";
import styles from "./styles.ts";
import useTranslate from "../../../../../Hooks/useTranslate.ts";

const UserMenu = () => {
    const {t} = useTranslate('translations');
    const handleSignOut = () => User.logout();

    return (
        <>
            <style>{css(styles.button)}</style>
            <Dropdown>
                <Dropdown.Toggle variant="link" className={css(styles.button)} id="dropdown-basic">
                    <img src={ProfileIcon} alt="Profile" className={css(styles.icon)}/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={handleSignOut}>{t("Logout")}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default UserMenu;
