import {BaseModel} from "../../Types/ModelTypes.ts";
import {vidyoConnectorState, vidyoConnectorStateDescription, vidyoDeviceState} from "./VidyoEnumerations.ts";
import {
    DeviceTypeIdAttributeNamesType, DeviceTypeSelectorsType,
    VidyoCamera, VidyoChatDtpIdType, VidyoChatDtpPacket,
    VidyoClientLibType,
    VidyoConnectionProperties,
    VidyoConnectorType, VidyoDevice, VidyoDeviceType,
    VidyoInitParams, VidyoMessage,
    VidyoMicrophone,
    VidyoParticipant,
    VidyoSpeaker
} from "../../Types/VidyoTypes.ts";
import Logger from "../../Utils/Logger.ts";
import {RootState, store} from "../../State/store.ts";
import {isObject} from "../../Utils/Helpers.ts";
import {Notify} from "../../Utils/Notify.ts";
import {vidyoSlice} from "./vidyoSlice.ts";
import {createSelector} from "@reduxjs/toolkit";
import Navigator from "../Navigator/Navigator.ts"

declare global {
    interface Window {
        VidyoClientLib: VidyoClientLibType
    }
}

class Vidyo implements BaseModel {
    host: string;
    displayName: string;
    roomKey: string;
    cameraViewId: string;
    remoteCameraViewId: string;
    state: keyof typeof vidyoConnectorState;
    isVideoInterceptorActivated: boolean;
    isVideoBackgroundActive?: boolean;
    connectionProperties?: VidyoConnectionProperties
    isLocalCameraEnabled: boolean;
    isLocalMicrophoneEnabled: boolean;
    isLocalSpeakerEnabled: boolean;
    // connected: boolean;
    cameras: { [id: string]: VidyoCamera } = {};
    microphones: { [id: string]: VidyoMicrophone } = {};
    speakers: { [id: string]: VidyoSpeaker } = {};
    receivedMessage: string;
    isRemoteParticipantConnected?: boolean;
    sendBandwidthPercent: number;
    receiveBandwidthPercent: number;
    sendBandwidth: string
    receiveBandwidth: string
    idFrontImageBuffer: string[];
    idBackImageBuffer: string[];
    selfieImageBuffer: string[];
    idFrontImageBufferCursor: number;
    idBackImageBufferCursor: number;
    selfieImageBufferCursor: number;

    vidyoConnector?: VidyoConnectorType;
    version?: string;
    // _stateDescription?: string;
    selectedCameraId?: string;
    // _selectedCameraName?: string;
    selectedMicrophoneId?: string;
    // _selectedMicrophoneName?: string;
    selectedSpeakerId?: string;
    selectedSpeakerName?: string;
    participant?: VidyoParticipant;
    participantName?: string;
    conferenceId?: string;
    callId?: string;


    constructor(params: VidyoInitParams) {
        this.displayName = params.displayName;
        this.roomKey = params.roomKey;
        this.host = import.meta.env.VITE_VIDYO_HOST;
        this.cameraViewId = import.meta.env.VITE_VIDYO_LOCAL_CAMERA_VIEW_ID;
        this.remoteCameraViewId = import.meta.env.VITE_VIDYO_REMOTE_CAMERA_VIEW_ID;
        this.state = vidyoConnectorState.VIDYO_CONNECTORSTATE_Idle as keyof typeof vidyoConnectorState;
        this.isVideoInterceptorActivated = false;
        this.isVideoBackgroundActive = import.meta.env.VITE_VIDYO_ENABLE_VIDEO_BACKGROUND === 'true' ?
            import.meta.env.VITE_VIDYO_DEFAULT_VIDEO_BACKGROUND_STATE === 'enabled' :
            false;
        this.isLocalCameraEnabled = false;
        this.isLocalMicrophoneEnabled = false;
        this.isLocalSpeakerEnabled = false;
        this.sendBandwidth = '0';
        this.receiveBandwidth = '0';
        this.sendBandwidthPercent = 0;
        this.receiveBandwidthPercent = 0;
        this.receivedMessage = "";
        this.isRemoteParticipantConnected = false;
        this.idFrontImageBuffer = [];
        this.idBackImageBuffer = [];
        this.selfieImageBuffer = [];
        this.idFrontImageBufferCursor = 0;
        this.idBackImageBufferCursor = 0;
        this.selfieImageBufferCursor = 0;

        this.save()
    }


    get selectedCameraName() {
        return this.getSelectedCamera()?.name
    }

    get selectedMicrophoneName() {
        return this.getSelectedMicrophone()?.name
    }

    get stateDescription() {
        if (this.state) {
            if (vidyoConnectorStateDescription[this.state as keyof typeof vidyoConnectorStateDescription]) {
                return vidyoConnectorStateDescription[this.state as keyof typeof vidyoConnectorStateDescription]
            } else {
                return this.state
            }
        }
        return 'Not initialized'
    }

    get roomName() {
        let displayName = ''
        if (Array.isArray(this.connectionProperties)) {
            this.connectionProperties.forEach((prop) => {
                if (prop.name === 'Room.displayName') {
                    displayName = prop.value;
                }
            });
        }
        return displayName;
    }

    get connected() {
        return this.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected
    }

    save = () => {
        if (vidyoSlice?.actions?.save && typeof vidyoSlice.actions.save === 'function') {
            store.dispatch(vidyoSlice.actions.save(JSON.parse(JSON.stringify(this))))
        } else {
            throw new Error(
                'Invalid save parameters. Slice parameter must include a save action',
            );
        }
    }

    initializeVidyoConnector = async () => {
        console.log("window.VidyoClientLib", window.VidyoClientLib)
        try {
            if (window.VidyoClientLib) {
                Logger.console('initializing Vidyo Connector...')
                const vidyoClient = new window.VidyoClientLib.VidyoClient('', initResult => {
                    const {state, description} = initResult;
                    Logger.console('Vidyo Client initialization description', description)
                    this.state = state;
                })
                Logger.console('VidyoConnector State before creation: ' + this.state)
                this.save()
                const vidyoConnector = await vidyoClient.CreateVidyoConnector({
                    viewId: null, // Set to null in order to create a custom layout
                    viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
                    remoteParticipants: 1,
                    // logFileFilter: 'debug@VidyoClient debug@VidyoSDP debug@VidyoResourceManager all@VidyoSignaling',
                    logFileFilter: 'error@VidyoClient',
                    logFileName: 'VidyoConnector.log',
                    userData: 0,
                    constraints: {
                        disableGoogleAnalytics: true,
                    },
                })
                Logger.console(vidyoConnector, 'VidyoConnector instance created')

                if (vidyoConnector) {
                    this.vidyoConnector = vidyoConnector;
                    this.version = await vidyoConnector.GetVersionWithoutBuildNumber();
                    // TODO: Enable debugging
                    /**
                     * vidyoConnector.EnableDebug({port:7776, logFilter: "warning info@VidyoClient info@VidyoConnector"}).then(function() {
                     *     console.log("EnableDebug success");
                     * }).catch(function() {
                     *     console.error("EnableDebug failed");
                     * });
                     */
                    this.save()
                    this.registerDeviceListeners()
                    this.registerParticipantEventListener()
                    this.registerResourceManagerEventListener()
                    this.registerMessageEventListener()
                }
                await this.updateVidyoState()
                Logger.console('VidyoConnector State after creation: ' + this.state)
                if (this.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Ready) {
                    // await this.vidyoConnector?.SetTCPTransport({enable: true})
                    // await vidyo.vidyoConnector?.SetUDPTransport({enable: true})
                }
            }
        } catch (e) {
            Logger.error("CreateVidyoConnector Failed ", e);
        }
    }
    connectToConference = async () => {
        this.vidyoConnector?.SetAdvancedConfiguration({
            loggerURL: '',
            extData: '',
            extDataType: '',
            showStatisticsOverlay: import.meta.env.VITE_VIDYO_SHOW_STATISTICS_OVERLAY === "true"
        });

        const connectResult = await this.vidyoConnector?.ConnectToRoomAsGuest({
            host: this.host,
            roomKey: this.roomKey || '',
            displayName: this.displayName,
            // roomPin: null,
            onSuccess: () => {
                this._handleConnectSuccess()
                this.save()

            },
            onDisconnected: (reason) => {
                this._handleDisconnect(reason)
                this.save()
            },
            onFailure: (reason) => {
                this._handleConnectFailure(reason)
                this.save()
            }
        })

        Logger.console("ConnectToRoomAsGuest Result", connectResult)
    }

    disconnect = async () => {
        if (this.vidyoConnector) {
            this.vidyoConnector?.Disconnect();
            await this.updateVidyoState()

        }
    }
    registerDeviceListeners = () => {
        this.registerLocalCameraEventListener()
        this.registerLocalMicrophoneEventListener()
        this.registerLocalSpeakerEventListener()
        this.registerRemoteCameraEventListener()
        this.registerRemoteMicrophoneEventListener()
        this.registerRemoteMicrophoneEnergyListener()

        this.save()
    }
    registerLocalCameraEventListener = async () => {
        await this.vidyoConnector?.RegisterLocalCameraEventListener({
            onAdded: (localCamera: VidyoCamera) => {
                Logger.console("Local Camera Added", localCamera)
                this.addCamera(localCamera)
                this.save();
            },
            onRemoved: async (localCamera: VidyoCamera) => {
                Logger.console("Local Camera Removed", localCamera)
                this.removeCamera(localCamera)
                if (this.selectedCameraId === localCamera.id) {
                    await this.hideView(this.cameraViewId)
                    this.vidyoConnector?.SelectDefaultCamera();
                }
            },
            onSelected: async (localCamera: VidyoCamera) => {
                Logger.console("Local Camera Selected", localCamera)
                if (localCamera) {
                    try {
                        const vidyoConstraintConfiguration = Vidyo.getVidyoConstraintConfiguration()
                        if (vidyoConstraintConfiguration) {
                            await localCamera?.SetMaxConstraint(vidyoConstraintConfiguration);
                        }
                        await this.vidyoConnector?.AssignViewToLocalCamera({
                            viewId: this.cameraViewId,
                            localCamera: localCamera,
                            displayCropped: false,
                            allowZoom: false
                        });
                        this.selectedCameraId = localCamera.id
                    } catch (e) {
                        if (e instanceof Error) {
                            Logger.error("Vidyo@onSelected - Assign View to Local Camera failed: ", e.message)
                        } else {
                            Logger.error("Vidyo@onSelected - Assign View to Local Camera failed: ", e)
                        }
                    }
                    if (this.isVideoBackgroundActive) {
                        await this.toggleVideoBackground(true)
                    }
                }

                this.save()
            },
            onStateUpdated: async (localCamera, state) => {
                Logger.console("Local Camera State Updated", JSON.stringify(localCamera) + "\n" + state + "\n")
                if (Vidyo.isDeviceStateDisabled(state)) {
                    this.isLocalCameraEnabled = false
                    if (this.isVideoBackgroundActive) {
                        await this.toggleVideoBackground(false)
                    }
                    // if (this.requestToReset) {
                    //     this.requestToReset = false
                    //     this.startCamera()
                    // }
                } else if (Vidyo.isDeviceStateEnabled(state)) {
                    this.isLocalCameraEnabled = true
                    try {
                        const onStateUpdateAssignResult = await this.vidyoConnector?.AssignViewToLocalCamera({
                            viewId: this.cameraViewId,
                            localCamera: localCamera,
                            displayCropped: false,
                            allowZoom: false
                        });
                        Logger.console(onStateUpdateAssignResult, "RegisterLocalCameraEventListener@onStateUpdated() - AssignResult\n=>")
                    } catch (e) {
                        if (e instanceof Error) {
                            Logger.error("Vidyo@onStateUpdated - Assign View to Local Camera failed: ", e.message)
                        } else {
                            Logger.error("Vidyo@onStateUpdated - Assign View to Local Camera failed: ", e)
                        }
                    }
                    if (this.isVideoBackgroundActive) {
                        await this.toggleVideoBackground(true)
                    }
                }
                this.save()
            },
        })
    }
    registerLocalMicrophoneEventListener = async () => {
        await this.vidyoConnector?.RegisterLocalMicrophoneEventListener({
            onAdded: (localMicrophone) => {
                // vidyo.log.info(localMicrophone, "Local Microphone Added")
                Logger.console("Local Microphone Added", localMicrophone)
                this.addMicrophone(localMicrophone)
                this.selectDefaultDevice("microphone")
                // await vidyo.vidyoConnector?.SelectDefaultMicrophone()
            },
            onRemoved: (localMicrophone) => {
                // vidyo.log.info(localMicrophone, "Local Microphone Removed")
                Logger.console("Local Microphone Removed", localMicrophone)
                this.removeMicrophone(localMicrophone)
            },
            onSelected: async (localMicrophone) => {
                // vidyo.log.info(localMicrophone, "Local Microphone Selected" )
                Logger.console("Local Microphone Selected", localMicrophone)
                this.selectedMicrophoneId = localMicrophone?.id
                await this.vidyoConnector?.SetMicrophonePrivacy({privacy: false})
                this.isLocalMicrophoneEnabled = true;
                this.save()
            },
            onStateUpdated: (localMicrophone, state) => {
                Logger.console("Local Microphone State Updated", JSON.stringify(localMicrophone) + "\n" + state)
                if (Vidyo.isDeviceStateDisabled(state)) {
                    this.isLocalMicrophoneEnabled = false
                } else if (
                    Vidyo.isDeviceStateEnabled(state)
                ) {
                    this.isLocalMicrophoneEnabled = true
                }
                this.save()
            },
        })
    }
    registerLocalSpeakerEventListener = () => {
        this.vidyoConnector?.RegisterLocalSpeakerEventListener({
            onAdded: (localSpeaker: VidyoSpeaker) => {
                // vidyo.log.info(localSpeaker, "Local Speaker Added")
                Logger.console("Local Speaker Added", localSpeaker)
                this.addSpeaker(localSpeaker)
                this.selectDefaultDevice("speaker")
                // vidyo.selectLocalSpeaker(localSpeaker)
                // vidyo.vidyoConnector.SelectDefaultSpeaker()

            },
            onRemoved: (localSpeaker: VidyoSpeaker) => {
                // vidyo.log.info(localSpeaker, "Local Speaker Removed")
                Logger.console("Local Speaker Removed", localSpeaker)
                this.removeSpeaker(localSpeaker)
            },
            onSelected: async (localSpeaker: VidyoSpeaker) => {
                // vidyo.log.info(localSpeaker, "Local Speaker selected")
                Logger.console("Local Speaker selected", localSpeaker)
                this.selectedSpeakerId = localSpeaker?.id
                await this.vidyoConnector?.SetSpeakerPrivacy({privacy: false})
                this.isLocalSpeakerEnabled = true
                this.save()
            },
            onStateUpdated: (localSpeaker: VidyoSpeaker, state: keyof typeof vidyoDeviceState) => {
                Logger.console("Local Speaker State Updated: \n", JSON.stringify(localSpeaker) + "\n" + state)
                if (Vidyo.isDeviceStateDisabled(state)) {
                    this.isLocalSpeakerEnabled = false
                } else if (
                    Vidyo.isDeviceStateEnabled(state)
                ) {
                    this.isLocalSpeakerEnabled = true
                }
                this.save()
            },
        })
    }
    registerRemoteCameraEventListener = () => {
        this.vidyoConnector?.RegisterRemoteCameraEventListener({
            onAdded: async (remoteCamera: VidyoCamera, participant: VidyoParticipant) => {
                Logger.console("Remote Camera Added: \n", JSON.stringify(remoteCamera) + "\n" + JSON.stringify(participant))
                this.save()
                await this.vidyoConnector?.AssignViewToRemoteCamera({
                    viewId: this.remoteCameraViewId || import.meta.env.VITE_VIDYO_REMOTE_CAMERA_VIEW_ID,
                    remoteCamera: remoteCamera,
                    displayCropped: false,
                    allowZoom: false
                });
            },
            onRemoved: async (remoteCamera: VidyoCamera, participant: VidyoParticipant) => {
                Logger.console("Remote Camera Removed: \n", JSON.stringify(remoteCamera) + "\n" + JSON.stringify(participant))
                await this.hideView(this.remoteCameraViewId || import.meta.env.VITE_VIDYO_REMOTE_CAMERA_VIEW_ID)
            },
            onStateUpdated: (remoteCamera: VidyoCamera, participant: VidyoParticipant, state: keyof typeof vidyoDeviceState) => {
                Logger.console("Remote Camera Updated: \n", JSON.stringify(remoteCamera) + "\n" + JSON.stringify(participant) + "\n" + state)
            },
        })
    }
    registerRemoteMicrophoneEventListener = () => {
        this.vidyoConnector?.RegisterRemoteMicrophoneEventListener({
            onAdded(remoteMicrophone, participant) {
                Logger.console("Remote Microphone Added: \n", JSON.stringify(remoteMicrophone) + "\n" + JSON.stringify(participant))
            },
            onRemoved(remoteMicrophone, participant) {
                Logger.console("Remote Microphone Removed: \n", JSON.stringify(remoteMicrophone) + "\n" + JSON.stringify(participant))
            },
            onStateUpdated(remoteMicrophone, participant) {
                Logger.console("Remote Microphone Updated: \n", JSON.stringify(remoteMicrophone) + "\n" + JSON.stringify(participant))
            },
        })
    }
    registerRemoteMicrophoneEnergyListener = () => {
        this.vidyoConnector?.RegisterRemoteMicrophoneEnergyListener({
            // @ts-expect-error keep for reference
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onEnergy(remoteMicrophone, remoteParticipant, energyLevelInfo) {
                // console.log("RegisterRemoteMicrophoneEnergyListener@onEnergy - remoteMicrophone", remoteMicrophone)
                // console.log("RegisterRemoteMicrophoneEnergyListener@onEnergy - energyLevelInfo", energyLevelInfo)
                // console.log("RegisterRemoteMicrophoneEnergyListener@onEnergy - something", energyLevelInfo)
            }
        })
    }
    registerParticipantEventListener = () => {
        this.vidyoConnector?.RegisterParticipantEventListener({
            onJoined: (participant: VidyoParticipant) => {
                Logger.console("Remote Participant Joined:", JSON.stringify(participant))
                this.participant = participant
                this.participantName = participant?.name
                Notify(`Participant ${participant.name} joined the room`, "success", 5000)
                this.save()
            },
            onLeft: (participant: VidyoParticipant) => {
                this.participant = undefined
                this.participantName = ''
                this.save()
                Logger.error("Remote Participant Left:", JSON.stringify(participant))
                Notify(`Participant ${participant.name} left the room`, "warn", 5000)
            },
            onDynamicChanged: (participants: VidyoParticipant[]) => {
                Logger.console("Ordered array of participants according to rank:", JSON.stringify(participants))
            },
            onLoudestChanged: (participant: VidyoParticipant, audioOnly: boolean) => {
                Logger.console("Current loudest speaker:", `${JSON.stringify(participant)}\n${audioOnly}`)
                /* Current loudest speaker */
            }
        })
    }
    registerResourceManagerEventListener = () => {
        this.vidyoConnector?.RegisterResourceManagerEventListener({
                onAvailableResourcesChanged: this.onAvailableResourcesChanged,
                onMaxRemoteSourcesChanged: this.onMaxRemoteSourcesChanged
            }
        )
    }
    /**@param cpuEncodeObj number
     * @param cpuDecodeObj number
     * @param bandwidthSendObj number
     * @param bandwidthReceiveObj number
     * lower numbers means bandwidth degrades. 100 - you have 100% capacity to transmit the expected resolution at expected framerate.
     * 75% - goes bad. 50% - close to bad, 25% - very bad and below
     */
        // @ts-expect-error keep for reference
    onAvailableResourcesChanged = (cpuEncodeObj: number, cpuDecodeObj: number, bandwidthSendObj: number, bandwidthReceiveObj: number) => {
        // eslint-disable-next-line max-len
        // Logger.console("%conAvailableResourcesChangedCallback: %c" + 'cpuEncodeObj=' + cpuEncodeObj +
        //   '; cpuDecodeObj=' + cpuDecodeObj + '; bandwidthSendObj=' + bandwidthSendObj + '; ' +
        //   'bandwidthReceiveObj=' + bandwidthReceiveObj, 'color: red;', 'color: salmon;');
        this.sendBandwidthPercent = bandwidthSendObj;
        this.receiveBandwidthPercent = bandwidthReceiveObj;
        this.save();
    }

    onMaxRemoteSourcesChanged = (maxRemoteSourcesObj: unknown) => {
        Logger.console('%conMaxRemoteSourcesChangedCallback EXAMPLE: %c maxRemoteSourcesObj=' + maxRemoteSourcesObj, 'color: red;', 'color: salmon;');
    }
    registerMessageEventListener = () => {
        this.vidyoConnector?.RegisterMessageEventListener({
            onChatMessageReceived: (participant: VidyoParticipant, chatMessage: VidyoMessage) => {
                this.handleMessageReceived(participant, chatMessage)
            }
        })
    }
    handleMessageReceived = (participant: VidyoParticipant, chatMessage: VidyoMessage) => {
        const packetIdentifier = import.meta.env.VITE_VIDYO_CHAT_DTP_IDENTIFIER;
        if (chatMessage?.body?.length && chatMessage.body.slice(0, packetIdentifier.length) === packetIdentifier) {
            // const indicatorPrecision = parseInt(import.meta.env.VITE_VIDYO_CHAT_DOWNLOAD_INDICATOR_PRECISION || '10')
            const dataString = chatMessage.body.slice(packetIdentifier.length)
            const payload: VidyoChatDtpPacket = JSON.parse(dataString)
            if (payload.type === "CAP") {
                const photo = Vidyo.takeLocalVideoScreenshot()
                this.sendBase64Image(payload.id, photo || '')
            } else if (payload.type === "RST") {
                // Resetting Audio/Video Devices
                if (payload.id === 'cam') {
                    this.stopCamera()
                    // this.requestToReset = true
                    this.startCamera()
                } else if (payload.id === 'mic') {
                    this.stopMic()
                    this.startMic()
                } else if (payload.id === 'spk') {
                    this.stopSpeaker()
                    this.startSpeaker()
                } else if (payload.id === 'all') {
                    this.stopCamera()
                    this.stopMic()
                    this.stopSpeaker()
                    this.startCamera()
                    this.startMic()
                    this.startSpeaker()
                }
            } else if (payload.type === "INI") {
                // Client will never receive an INI package
                try {
                    // if (payload.id === 'IDFront') {
                    //     MdiClient.setIDFrontImageUri(null)
                    //     this.idFrontImageBuffer = Array(payload.data)
                    //     this.idFrontImageBufferCursor = 0
                    //     this.isReceivingIDFront = true
                    //     Vidyo.setIDFrontReceiveProgress(0)
                    // } else if (payload.id === 'IDBack') {
                    //     MdiClient.setIDBackImageUri(null)
                    //     this.idBackImageBuffer = Array(payload.data)
                    //     this.idBackImageBufferCursor = 0
                    //     this.isReceivingIDBack = true
                    //     Vidyo.setIDBackReceiveProgress(0)
                    // } else if (payload.id === 'selfie') {
                    //     MdiClient.setSelfieImageUri(null)
                    //     this.selfieImageBuffer = Array(payload.data)
                    //     this.selfieImageBufferCursor = 0
                    //     this.isReceivingSelfie = true
                    //     Vidyo.setSelfieReceiveProgress(0)
                    // }
                    this.save()
                } catch (e) {
                    Logger.error("Vidyo@handleMessageReceived -> Exception thrown while processing received INI packet", e instanceof Error ? e.message : e)
                }
            } else if (payload.type === "DAT") {
                // Client will never receive an DAT package
                try {
                    // if (payload.id === 'IDFront') {
                    //     this.idFrontImageBufferCursor = payload.index
                    //     this.idFrontImageBuffer[this.idFrontImageBufferCursor] = payload.data
                    //     const progress = parseInt((this.idFrontImageBufferCursor / this.idFrontImageBuffer.length) * 100)
                    //     if (parseInt(progress) % indicatorPrecision === 0 && this.idFrontReceiveProgress !== progress) {
                    //         this.idFrontReceiveProgress = progress
                    //         Vidyo.setIDFrontReceiveProgress(progress)
                    //     }
                    // } else if (payload.id === 'IDBack') {
                    //     this.idBackImageBufferCursor = payload.index
                    //     this.idBackImageBuffer[this.idBackImageBufferCursor] = payload.data
                    //     const progress = parseInt((this.idBackImageBufferCursor / this.idBackImageBuffer.length) * 100)
                    //     if (parseInt(progress) % indicatorPrecision === 0 && this.idBackReceiveProgress !== progress) {
                    //         this.idBackReceiveProgress = progress
                    //         Vidyo.setIDBackReceiveProgress(progress)
                    //     }
                    // } else if (payload.id === 'selfie') {
                    //     this.selfieImageBufferCursor = payload.index
                    //     this.selfieImageBuffer[this.selfieImageBufferCursor] = payload.data
                    //     const progress = parseInt((this.selfieImageBufferCursor / this.selfieImageBuffer.length) * 100)
                    //     if (parseInt(progress) % indicatorPrecision === 0 && this.selfieReceiveProgress !== progress) {
                    //         this.selfieReceiveProgress = progress
                    //         Vidyo.setSelfieReceiveProgress(progress)
                    //     }
                    // }
                } catch (e) {
                    Logger.error("Vidyo@handleMessageReceived -> Exception thrown while processing received DAT packet", e instanceof Error ? e.message : e)
                }
            } else if (payload.type === 'END') {
                // Client will never receive an INI package
                try {
                    // if (payload.id === 'IDFront') {
                    //     Vidyo.setIDFrontReceiveProgress(100)
                    //     const prefix = this.idFrontImageBuffer[0].slice(0, 10) === 'data:image' ? '' : 'data:image/jpeg;base64,'
                    //     MdiClient.setIDFrontImageUri({imgUri: prefix + this.idFrontImageBuffer.join(""), uuid: uuid()})
                    //     this.isReceivingIDFront = false
                    // } else if (payload.id === 'IDBack') {
                    //     Vidyo.setIDBackReceiveProgress(100)
                    //     const prefix = this.idBackImageBuffer[0].slice(0, 10) === 'data:image' ? '' : 'data:image/jpeg;base64,'
                    //     MdiClient.setIDBackImageUri({imgUri: prefix + this.idBackImageBuffer.join(""), uuid: uuid()})
                    //     this.isReceivingIDBack = false
                    // } else if (payload.id === 'selfie') {
                    //     Vidyo.setSelfieReceiveProgress(100)
                    //     const prefix = this.selfieImageBuffer[0].slice(0, 10) === 'data:image' ? '' : 'data:image/jpeg;base64,'
                    //     MdiClient.setSelfieImageUri({imgUri: prefix + this.selfieImageBuffer.join(""), uuid: uuid()})
                    //     this.isReceivingSelfie = false
                    // }
                } catch (e) {
                    Logger.error("Vidyo@handleMessageReceived -> Exception thrown while processing received END packet", e instanceof Error ? e.message : e)
                }
                this.save()
            }
        } else {

            // this.showMessage(chatMessage.body)
            Logger.console("Message received", chatMessage)
            Logger.console("From participant", participant)
            const typeMessage = chatMessage.body.split('_');
            if (typeMessage) {
                if (typeMessage[0] === 'switch') {
                    // Why cycle camera and mic?

                    // window.CycleCamera();
                    // await window.CycleMicrophone();
                    // await window.CycleMicrophone();
                    // await window.CycleMicrophone();
                }
            } else if (typeMessage[0] === 'mobile') {
                // this.showMessage(typeMessage[1]);
            } else if (typeMessage[0] === 'otp') {
                Navigator.setShowOtpModal(true);
            }
        }

    }
    showMessage = (message: string) => {
        Logger.console("Showing Message", message)
        this.receivedMessage = message
        this.save()
    }

    sendGroupChatMessage = (message: string) => {
        const sendMessageResult = this.vidyoConnector?.SendChatMessage({
            message: message
        })
        if (sendMessageResult) {
            Logger.console("Message transmitted successfully", sendMessageResult)
        } else {
            Logger.error("Message failed to transmit", sendMessageResult)
        }
    }

    sendPrivateChatMessage = async (message: string) => {
        if (message.length > import.meta.env.VITE_VIDYO_CHAT_MAX_MESSAGELENGTH) {
            Logger.error("Vidyo@sendPrivateChatMessage: Message exceeds maximum allowed size")
            return false
        }
        if (!this.participant) {
            Logger.error("Vidyo@sendPrivateChatMessage: No participant found")
            return false
        }
        if (!message) {
            Logger.error("Vidyo@sendPrivateChatMessage: Message cannot be empty")
            return false
        }
        try {
            return await this.vidyoConnector?.SendPrivateChatMessage({
                participant: this.participant,
                message: message
            })
        } catch (e) {
            if (e instanceof Error) {
                Logger.error("Vidyo@sendPrivateChatMessage Exception", e.message)
            } else {
                Logger.error("Vidyo@sendPrivateChatMessage Exception", e)
            }
            return false
        }
    }
    sendBase64Image = async (imgType: VidyoChatDtpIdType, base64Image: string) => {
        const packetIdentifier = import.meta.env.VITE_VIDYO_CHAT_DTP_IDENTIFIER;
        const size = base64Image.length
        const chunkSize = parseInt(import.meta.env.VITE_VIDYO_CHAT_DTP_CHUNK_SIZE)
        if (size) {
            let chunkCount = Math.floor(size / chunkSize)
            const rem = size % chunkSize
            if (rem > 0) {
                chunkCount++
            }
            this.selfieImageBuffer = []
            this.selfieImageBufferCursor = 0
            for (let i = 0; i < chunkCount; i++) {
                const index = this.selfieImageBufferCursor * chunkSize
                this.selfieImageBuffer.push(base64Image.slice(index, index + chunkSize))
                this.selfieImageBufferCursor++
            }
            const initPacket = {
                id: imgType,
                type: "INI",
                data: chunkCount,
            }
            await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(initPacket))
            for (let i = 0; i < this.selfieImageBuffer.length; i++) {
                const dtpPacket = {
                    id: imgType,
                    index: i,
                    type: "DAT",
                    data: this.selfieImageBuffer[i],
                }
                await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(dtpPacket))
            }
            const endPacket = {
                id: imgType,
                type: "END",
                index: this.selfieImageBuffer.length,
                data: size,
            }
            await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(endPacket))
        }
    }
    requestRemoteCameraCapture = async (imgType: VidyoChatDtpIdType) => {
        const packetIdentifier = import.meta.env.VITE_VIDYO_CHAT_DTP_IDENTIFIER;
        if (['IDFront', 'IDBack', 'selfie'].includes(imgType)) {
            const packet = {
                id: imgType,
                type: "CAP",
            }
            return await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(packet))
        } else {
            Logger.error("Vidyo.js@requestRemoteCameraCapture -> Unsupported type: " + imgType)
            return false
        }

    }
    requestRemoteDevicesReset = async (device: VidyoChatDtpIdType) => {
        const packetIdentifier = import.meta.env.VITE_VIDYO_CHAT_DTP_IDENTIFIER;
        if (['all', 'cam', 'mic', 'spk'].includes(device)) {
            const packet = {
                id: device,
                type: "RST",
            }
            return await this.sendPrivateChatMessage(packetIdentifier + JSON.stringify(packet))
        } else {
            Logger.error("Vidyo.js@requestRemoteDevicesReset -> Unsupported device type: " + device)
            return false
        }
    }
    _handleConnectSuccess = async () => {
        // this.connectionProperties = await this.vidyoConnector?.GetConnectionProperties();
        this.updateVidyoState()
    }

    _handleDisconnect = (reason: string) => {
        Logger.console('_handleDisconnect reason', reason)
        this.participantName = ''
        this.conferenceId = ''
        this.callId = ''
        // this.state = reason
        this.updateVidyoState()
    }
    _handleConnectFailure = (reason: string) => {
        Logger.console('_handleConnectFailure reason', reason)
        // this.state = reason
        this.participantName = ''
        this.conferenceId = ''
        this.callId = ''
        this.updateVidyoState()
    }
    addCamera = (camera: VidyoCamera) => {
        this.cameras = {
            ...this.cameras,
            [camera.id]: camera
        }
    }
    removeCamera = (camera: VidyoCamera) => {
        if (camera?.id && this.cameras[camera.id]) {
            delete this.cameras[camera.id]
        }
    }
    addMicrophone = (mic: VidyoMicrophone) => {
        this.microphones = {
            ...this.microphones,
            [mic.id]: mic
        }
    }
    removeMicrophone = (mic: VidyoMicrophone) => {
        if (mic?.id && this.microphones[mic.id]) {
            delete this.microphones[mic.id]
        }
    }
    addSpeaker = (speaker: VidyoSpeaker) => {
        this.speakers = {
            ...this.speakers,
            [speaker.id]: speaker
        }
    }
    removeSpeaker = (speaker: VidyoSpeaker) => {
        if (speaker?.id && this.speakers[speaker.id]) {
            delete this.speakers[speaker.id]
        }
    }
    hideView = async (viewId: string) => {
        await this.vidyoConnector?.HideView({
            viewId: viewId
        });
    }
    hideLocalCameraView = () => {
        this.hideView(this.cameraViewId)
    }
    hideRemoteCameraView = () => {
        this.hideView(this.remoteCameraViewId)
    }
    getSelectedCamera = () => {
        if (this.selectedCameraId && this.cameras && this.cameras[this.selectedCameraId]) {
            return this.cameras[this.selectedCameraId]
        } else {
            return undefined
        }
    }
    selectCameraById = async (cameraId: string) => {
        if (this.cameras[cameraId]) {
            await this.selectLocalCamera(this.cameras[cameraId])
        }
    }
    getSelectedMicrophone = () => {
        if (this.selectedMicrophoneId && this.microphones && this.microphones[this.selectedMicrophoneId]) {
            return this.microphones[this.selectedMicrophoneId]
        } else {
            return undefined
        }
    }
    showSelectedLocalCamera = async () => {
        const selectedCamera = this.getSelectedCamera()
        if (selectedCamera) {
            try {
                this.vidyoConnector?.AssignViewToLocalCamera({
                    viewId: this.cameraViewId,
                    localCamera: this.getSelectedCamera(),
                    displayCropped: false,
                    allowZoom: false
                });
            } catch (e) {
                if (e instanceof Error) {
                    Logger.error('Vidyo@showSelectedLocalCamera - Assign View to Local Camera failed: ', e.message, selectedCamera)
                } else {
                    Logger.error('Vidyo@showSelectedLocalCamera - Assign View to Local Camera failed: ', e, selectedCamera)
                }
            }
            if (this.isVideoBackgroundActive) {
                await this.toggleVideoBackground(true)
            }
        }
    }
    startCamera = () => {
        const selectedCamera = this.getSelectedCamera()
        this.isVideoBackgroundActive = true;
        if (selectedCamera) {
            this.selectLocalCamera(selectedCamera)
        }
        this.vidyoConnector?.SetCameraPrivacy({privacy: false})
    }
    stopCamera = () => {
        this.vidyoConnector?.SetCameraPrivacy({privacy: true})
    }
    startMic = () => {
        this.vidyoConnector?.SetMicrophonePrivacy({privacy: false})
    }
    stopMic = () => {
        this.vidyoConnector?.SetMicrophonePrivacy({privacy: true})
    }
    startSpeaker = () => {
        this.vidyoConnector?.SetSpeakerPrivacy({privacy: false})
    }
    stopSpeaker = () => {
        this.vidyoConnector?.SetSpeakerPrivacy({privacy: true})
    }
    selectLocalCamera = async (camera: VidyoCamera) => {
        await this.vidyoConnector?.SelectLocalCamera({
            localCamera: camera
        })
    }
    selectLocalMic = (mic: VidyoMicrophone) => {
        this.vidyoConnector?.SelectLocalMicrophone({
            localMicrophone: mic
        })
    }
    selectMicById = (micId: string) => {
        if (this.microphones[micId]) {
            this.selectLocalMic(this.microphones[micId])
        }
    }
    selectLocalSpeaker = (speaker: VidyoSpeaker) => {
        this.vidyoConnector?.SelectLocalSpeaker({
            localSpeaker: speaker
        })
    }
    selectSpeakerById = (speakerId: string) => {
        if (this.speakers[speakerId]) {
            this.selectLocalSpeaker(this.speakers[speakerId])
        }
    }
    toggleVideoBackground = async (force?: boolean) => {
        if (import.meta.env.VITE_VIDYO_ENABLE_VIDEO_BACKGROUND === 'true') {

            if ((this.isVideoBackgroundActive && force === null) || force === false) {
                this.isVideoBackgroundActive = false;
                if (this.isVideoInterceptorActivated) {
                    Logger.console('Disabling Video Background')
                    await this.vidyoConnector?.UnregisterLocalCameraStreamInterceptor()
                    this.isVideoInterceptorActivated = false;
                }
                this.save();
            } else if ((!this.isVideoBackgroundActive && force === null) || force === true) {
                this.isVideoBackgroundActive = true;
                if (!this.isVideoInterceptorActivated) {
                    Logger.console('Enabling Video Background')
                    try {
                        // @ts-expect-error MediaPipePlugin
                        const mediaPipePlugin = await import('./MediaPipePlugin.js');
                        const streamInterceptorSucceeded = await this.vidyoConnector?.RegisterLocalCameraStreamInterceptor(mediaPipePlugin.mediaPipeVideoBackground)
                        if (streamInterceptorSucceeded) {
                            this.isVideoInterceptorActivated = true;
                            Logger.console('RegisterLocalCameraStreamInterceptor SUCCESS')
                        } else {
                            this.isVideoInterceptorActivated = false;
                            Logger.error('RegisterLocalCameraStreamInterceptor FAILED')
                        }
                    } catch (e) {
                        Logger.console('vidyoConnector?.RegisterLocalCameraStreamInterceptor(mediaPipePlugin.mediaPipeVideoBackground) EXCEPTION:\n', e)
                        this.isVideoInterceptorActivated = false;
                    }

                }
                this.save();
            }
        }
    }
    updateVidyoState = async () => {
        try {
            if (this.vidyoConnector) {
                this.connectionProperties = await this.vidyoConnector.GetConnectionProperties();
                this.state = await this.vidyoConnector.GetState()
                Logger.console("updateVidyoState", this.state)
            } else {
                this.state = vidyoConnectorState.VIDYO_CONNECTORSTATE_Idle as keyof typeof vidyoConnectorState;
            }
            this.save()
        } catch (e) {
            Logger.warn("Exception thrown while updating Vidyo state", e)
        }
    }

    getStats = async () => {
        if (this.vidyoConnector) {
            let conferenceId = ''
            let callId = ''
            let sendBandwidth = '0'
            let receiveBandwidth = '0'
            try {
                const analyticsJson = await this.vidyoConnector.GetStatsJson()
                const analytics = JSON.parse(analyticsJson)
                // Logger.console("GetStatsJson()", analytics)
                // Logger.console(analytics)
                try {
                    conferenceId = analytics.userStats[0].roomStats[0].conferenceId;
                    callId = analytics.userStats[0].roomStats[0].callId;
                    sendBandwidth = (analytics.userStats[0].roomStats[0].sendBitRateTotal / 1024).toFixed(3);
                    receiveBandwidth = (analytics.userStats[0].roomStats[0].receiveBitRateTotal / 1024).toFixed(3);

                } catch {
                    Logger.error("Vidyo@getStats - Exception thrown while parsing analytics JSON", analyticsJson)
                }
            } catch (e) {
                Logger.error("Vidyo.getStats() Exception", e)
            } finally {
                let shouldSave = false
                if (this.conferenceId !== conferenceId) {
                    this.conferenceId = conferenceId
                    shouldSave = true
                }
                if (this.callId !== callId) {
                    this.callId = callId
                    shouldSave = true
                }
                if (this.sendBandwidth !== sendBandwidth) {
                    this.sendBandwidth = sendBandwidth
                    shouldSave = true
                }
                if (this.receiveBandwidth !== receiveBandwidth) {
                    this.receiveBandwidth = receiveBandwidth
                    shouldSave = true
                }
                if (shouldSave) {
                    this.save()
                }
            }
        }
    }
    selectDefaultDevice = (type: VidyoDeviceType) => {
        const deviceTypes = ["camera", "microphone", "speaker"]
        const deviceTypeIdAttributeNames: DeviceTypeIdAttributeNamesType = {
            camera: "selectedCameraId",
            microphone: "selectedMicrophoneId",
            speaker: "selectedSpeakerId"
        }
        const deviceTypeSelectors: DeviceTypeSelectorsType = {
            camera: this.selectLocalCamera,
            microphone: this.selectLocalMic,
            speaker: this.selectLocalSpeaker
        }
        if (deviceTypes.includes(type)) {
            let devices: { [id: string]: VidyoDevice } = {};
            if (type === 'camera') {
                devices = {...this.cameras};
            } else if (type === 'microphone') {
                devices = {...this.microphones};
            } else if (type === 'speaker') {
                devices = {...this.speakers};
            }
            let defaultDevice = null;
            let selectedDevice: VidyoCamera | VidyoMicrophone | VidyoSpeaker | null = null;
            if (devices && isObject(devices)) {
                const keys = Object.keys(devices)
                // Find the default device
                keys.forEach(key => {
                    if (devices[key].id === "default") {
                        defaultDevice = devices[key]
                        Logger.console("default " + type + " found", defaultDevice)
                    }
                })
                // Find the original device that corresponds to the default device
                if (defaultDevice) {
                    const deviceVPID = Vidyo.getDeviceVPID(defaultDevice)
                    Logger.console("default " + type + " VID:PID", deviceVPID)
                    // Find and select original device by VID:PID
                    if (deviceVPID) {
                        keys.forEach(key => {
                            if (Vidyo.getDeviceVPID(devices[key]) === deviceVPID && Vidyo.getAudioDeviceType(devices[key]) === 0) {
                                selectedDevice = devices[key]
                                Logger.console("Selected " + type + " with VID:PID " + deviceVPID, selectedDevice)
                            }
                        })
                    }
                    // If device didn't have VID:PID or an original device with that VID:PID was not found, find and select the first original device
                    if (!selectedDevice) {
                        Logger.console(type + " with VID:PID " + deviceVPID + " was not found")
                        Logger.console("Searching for first original device")
                        keys.forEach(key => {
                            if (!selectedDevice && Vidyo.getAudioDeviceType(devices[key]) === 0) {
                                selectedDevice = devices[key]
                                Logger.console("selected first original " + type, selectedDevice)
                            }
                        })
                    }
                    // If an original device was not found, select the first device in collection
                    if (!selectedDevice) {
                        selectedDevice = devices[keys[0]];
                        Logger.console("selected first " + type + " in collection", selectedDevice)
                    }
                } else {
                    // If a default device was not found, select the first device in collection
                    selectedDevice = devices[keys[0]];
                    Logger.console("selected first " + type + " in collection 2", selectedDevice)
                }
                const deviceTypeIdAttributeName = deviceTypeIdAttributeNames[type];
                if (this[deviceTypeIdAttributeName] !== selectedDevice.id) {
                    if (type === 'camera') {
                        deviceTypeSelectors[type](selectedDevice as VidyoCamera)
                    } else if (type === 'microphone') {
                        deviceTypeSelectors[type](selectedDevice as VidyoMicrophone)
                    } else if (type === 'speaker') {
                        deviceTypeSelectors[type](selectedDevice as VidyoSpeaker)
                    }
                }
            }
        }

    }
    static getVidyoConstraintConfiguration = () => {
        const {
            VITE_ENABLE_VIDYO_CONSTRAINT,
            VITE_VIDYO_CONSTRAINT_WIDTH,
            VITE_VIDYO_CONSTRAINT_HEIGHT,
            VITE_VIDYO_CONSTRAINT_FRAME_INTERVAL
        } = import.meta.env;
        Logger.console("vidyo constraint configuration", VITE_ENABLE_VIDYO_CONSTRAINT + ": " + typeof VITE_ENABLE_VIDYO_CONSTRAINT,
            VITE_VIDYO_CONSTRAINT_WIDTH + ": " + typeof VITE_VIDYO_CONSTRAINT_WIDTH,
            VITE_VIDYO_CONSTRAINT_HEIGHT + ": " + typeof VITE_VIDYO_CONSTRAINT_HEIGHT,
            VITE_VIDYO_CONSTRAINT_FRAME_INTERVAL + ": " + typeof VITE_VIDYO_CONSTRAINT_FRAME_INTERVAL)
        if (VITE_ENABLE_VIDYO_CONSTRAINT === 'true') {
            const reactAppVidyoConstraintWidth = parseInt(VITE_VIDYO_CONSTRAINT_WIDTH)
            const reactAppVidyoConstraintHeight = parseInt(VITE_VIDYO_CONSTRAINT_HEIGHT)
            const reactAppVidyoConstraintFrameInterval = parseInt(VITE_VIDYO_CONSTRAINT_FRAME_INTERVAL)
            // @ts-expect-error its intentional
            if (String(reactAppVidyoConstraintWidth) === reactAppVidyoConstraintWidth && String(reactAppVidyoConstraintHeight) === reactAppVidyoConstraintHeight && String(reactAppVidyoConstraintFrameInterval) === reactAppVidyoConstraintFrameInterval &&
                reactAppVidyoConstraintWidth >= 320 &&
                reactAppVidyoConstraintHeight >= 180 &&
                reactAppVidyoConstraintFrameInterval >= 5) {
                return {
                    width: reactAppVidyoConstraintWidth,
                    height: reactAppVidyoConstraintHeight,
                    frameInterval: reactAppVidyoConstraintFrameInterval
                }
            }
        }
        return null;
    }
    isConnected = async () => {
        if (this.state) {
            await this.updateVidyoState()
            return this.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected
        } else {
            return false
        }

    }
    static formatDeviceName = (deviceName: string) => {
        const parts = deviceName?.split("(") || ['N/A()']
        if (parts.length > 1 && parts[parts.length - 1].indexOf(":") > -1) {
            parts.pop()
        }
        return parts.join("(")
    }
    static getAudioDeviceName = (device: VidyoDevice) => {
        if (device) {
            const name = device.name
            if (name && name.length) {
                const hasDeviceId = name.slice(-1) === ')' && name.slice(-11, -10) === '('
                if (name.slice(0, 10) === 'Default - ') {
                    return hasDeviceId ? name.slice(10, -12) : name.slice(10)
                } else if (name.slice(0, 13) === 'Προεπιλογή - ') {
                    return hasDeviceId ? name.slice(13, -12) : name.slice(13)
                } else if (name.slice(0, 17) === 'Communications - ') {
                    return hasDeviceId ? name.slice(17, -12) : name.slice(17)
                } else if (name.slice(0, 15) === 'Επικοινωνίες - ') {
                    return hasDeviceId ? name.slice(15, -12) : name.slice(15)
                } else {
                    return hasDeviceId ? name.slice(0, -12) : name
                }
            }
        }
        return 'Undefined'
    }
    static getAudioDeviceType = (device: VidyoDevice) => {
        if (device) {
            const name = device.name
            if (name && name.length) {
                if (name.slice(0, 10) === 'Default - ' || name.slice(0, 13) === 'Προεπιλογή - ') {
                    return 1;
                } else if (name.slice(0, 17) === 'Communications - ' || name.slice(0, 15) === 'Επικοινωνίες - ') {
                    return 2;
                } else {
                    return 0
                }
            }
        }
        return null
    }
    static getDeviceVPID = (device: VidyoDevice) => {
        if (device?.name?.length) {
            if (device.name.slice(-1) === ')' && device.name.slice(-11, -10) === '(') {
                return device.name.slice(-10, -1)
            }
        }
        return null
    }
    static getDeviceVID = (device: VidyoDevice) => {
        if (device?.name?.length) {
            if (device.name.slice(-1) === ')' && device.name.slice(-11, -10) === '(') {
                return device.name.slice(-10, -6)
            }
        }
        return null
    }
    static getDevicePID = (device: VidyoDevice) => {
        if (device?.name?.length) {
            if (device.name.slice(-1) === ')' && device.name.slice(-11, -10) === '(') {
                return device.name.slice(-5, -1)
            }
        }
        return null
    }

    static isOriginalAudioDevice = (device: VidyoDevice) => {
        return device?.id !== 'default' && device?.id !== 'communications'
    }

    static isDeviceStateDisabled = (state: keyof typeof vidyoDeviceState) => {
        return state === vidyoDeviceState.VIDYO_DEVICESTATE_Stopped ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Removed ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_InUse ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Error ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Suspended ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Paused
    }
    static isDeviceStateEnabled = (state: keyof typeof vidyoDeviceState) => {
        return state === vidyoDeviceState.VIDYO_DEVICESTATE_Started ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Unsuspended ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Available ||
            state === vidyoDeviceState.VIDYO_DEVICESTATE_Resumed
    }

    static takeRemoteVideoScreenshot = () => {
        const remoteVideoElement = document.querySelector<HTMLVideoElement>(`#${import.meta.env.VITE_VIDYO_REMOTE_CAMERA_VIEW_ID} video`)
        if (remoteVideoElement) {
            const canvas = document.createElement('canvas');
            canvas.width = remoteVideoElement.videoWidth
            canvas.height = remoteVideoElement.videoHeight
            const context = canvas.getContext('2d');
            context?.drawImage(remoteVideoElement, 0, 0, canvas.width, canvas.height);
            const dataURI = canvas.toDataURL('image/jpeg');
            Logger.console(dataURI)
            return dataURI
        }
    }
    static takeLocalVideoScreenshot = () => {
        const localVideoElement = document.querySelector<HTMLVideoElement>(`#${import.meta.env.VITE_VIDYO_LOCAL_CAMERA_VIEW_ID} video`)
        if (localVideoElement) {
            const canvas = document.createElement('canvas');
            canvas.width = localVideoElement.videoWidth!
            canvas.height = localVideoElement.videoHeight!
            const context = canvas.getContext('2d');
            context?.drawImage(localVideoElement, 0, 0, canvas.width, canvas.height);
            return canvas.toDataURL('image/jpeg')
        }
    }

    /** SELECTORS **/
    static selectVidyo = createSelector([(state: RootState) => state.vidyo], (vidyoState) => {
        return vidyoState.data;
    })
}

export default Vidyo
