import React from 'react';
import {css} from 'aphrodite';


import styles from './styles';
import logo from '../../../Images/YMA.png'
import {useNavigate} from "react-router-dom";
import {vidyoConnectorState} from "../../../../../Models/Vidyo/VidyoEnumerations.ts";
import {Notify} from "../../../../../Utils/Notify.ts";
import {useAppSelector} from "../../../../../State/hooks.ts";
import Vidyo from "../../../../../Models/Vidyo/Vidyo.ts";

const Logo = () => {
    const navigate = useNavigate();
    const vidyo = useAppSelector(Vidyo.selectVidyo);
    const handleLogoClick = () => {
        if (vidyo?.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected) {
            Notify("You are connected to a video session. Please disconnect from the current session before leaving this page.", "warn", 4000)
        } else {
            navigate("/")
        }
    }
    return (
        <div className={css(styles.logoContainer)}>
            <img alt={"logo"} src={logo} className={css(styles.image)}
                 onClick={handleLogoClick}
            />
        </div>
    );
};

const memoizedLogo = React.memo(Logo);
export {memoizedLogo as Logo};
