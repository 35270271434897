import {css} from "aphrodite";
import styles from "./styles.js";
import Vidyo from "../../../Models/Vidyo/Vidyo.ts";
import {MicrophoneSelectProps} from "./MicrophoneSelectProps.ts";
import Form from "react-bootstrap/Form";

const MicrophoneSelect = (props: MicrophoneSelectProps) => {
    const {microphones, selectedMicrophoneId, selectMicrophoneById, disabled} = props
    if (microphones) {
        return (
            <Form.Group controlId={'formSelectMicrophone'} className={css(styles.deviceSelect)}>
                <Form.Label>Mic</Form.Label>
                <Form.Select name="microphoneId" id={"microphoneId"} size={"lg"}
                             value={selectedMicrophoneId || ''} onChange={selectMicrophoneById}
                             disabled={disabled}>
                    {Object.keys(microphones)?.map(micId =>
                        <option value={micId} key={micId}>
                            {Vidyo.formatDeviceName(microphones[micId]?.name)}
                        </option>
                    )}
                </Form.Select>
            </Form.Group>
        )
    }
    return null;
}
export default MicrophoneSelect;
