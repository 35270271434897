import {
    getApplications,
    createNewApplication,
    applicationsSlice,
    getApplication,
    cancelApplication
} from "./applicationsSlice.ts";
import {RootState, store} from "../../State/store.ts";
import {createSelector} from "@reduxjs/toolkit";
import {BaseModel} from "../../Types/ModelTypes.ts";
import {ApiJsonResponse, ApplicationType} from "../../Types";
import {ApplicationStatus, nonTerminalStatuses} from "../../Utils/Enumerations.ts";
import {ApplicationFormData} from "../../Types/ApplicationTypes.ts";
import Logger from "../../Utils/Logger.ts";


class Application implements BaseModel {
    public id: string;
    public userId: string | null;
    public status: number; // integer
    // public workflowId: string;
    // public termsAccepted: boolean;
    public documentType: number;
    public documentNumber: string;
    public documentIssueDate: string;
    public documentExpiryDate: string;
    public documentIssueCountry: string;
    // public documentFile: string;
    public createdAt: string;
    public appointmentStart: string;
    public appointmentEnd: string;
    public workflowId: string;
    public videoSessionId: string;

    constructor(data: ApplicationType) {
        this.id = data.id;
        this.userId = data.id
        this.status = data.status;
        // this.workflowId = data.workflowId;
        // this.termsAccepted = data.termsAccepted;
        this.documentType = data.documentType;
        this.documentNumber = data.documentNumber;
        this.documentIssueDate = data.documentIssueDate;
        this.documentExpiryDate = data.documentExpiryDate;
        this.documentIssueCountry = data.documentIssueCountry;
        // this.documentFile = data.documentFile;
        this.createdAt = data.createdAt;
        this.createdAt = data.createdAt;
        this.appointmentStart = data.appointmentStart;
        this.appointmentEnd = data.appointmentEnd;
        this.workflowId = data.workflowId;
        this.videoSessionId = data.videoSessionId;
    }

    save = () => {
        if (applicationsSlice?.actions?.save && typeof applicationsSlice.actions.save === 'function') {
            console.log("this", this)
            store.dispatch(applicationsSlice.actions.save(JSON.parse(JSON.stringify(this))))
        } else {
            throw new Error(
                'Invalid save parameters. Slice parameter must include a save action',
            );
        }
    }

    /** ACTIONS **/
    static getApplications = async () => {
        const result = await store.dispatch(getApplications());
        if (result.meta.requestStatus === 'rejected') {
            // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
            if (result?.error?.message) {
                // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
                return Promise.reject(result.error.message)
            }
            return Promise.reject("Fetching Applications failed")
        }
        return result.payload
    }
    static getApplication = async (id: string) => {
        const result = await store.dispatch(getApplication(id));
        if (result.meta.requestStatus === 'rejected') {
            // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
            if (result?.error?.message) {
                // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
                return Promise.reject(result.error.message)
            }
            return Promise.reject("Fetching Applications failed")
        }
        return result.payload
    }
    static createNewApplication = async (formData: ApplicationFormData): Promise<ApiJsonResponse<Application>> => {
        const result = await store.dispatch(createNewApplication(formData));
        if (result.meta.requestStatus === 'rejected') {
            // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
            if (result?.error?.message) {
                // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
                return Promise.reject(result.error.message)
            }
            return Promise.reject("Creating new Application failed")
        }
        return result.payload as Promise<ApiJsonResponse<Application>>
    }
    cancel = async () => {
        if (this.cancellable) {
            const result = await store.dispatch(cancelApplication(this.id));
            if (result.meta.requestStatus === 'rejected') {
                // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
                if (result?.error?.message) {
                    // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
                    return Promise.reject(result.error.message)
                }
                return Promise.reject("Cancel Application failed")
            }
            return result.payload as Promise<ApiJsonResponse<Application>>
        }
    }


    get created() {
        return new Date(this.createdAt).toLocaleString()
    }

    get startsAt() {
        return new Date(this.appointmentStart).toString()
    }

    get endsAt() {
        return new Date(this.appointmentEnd).toString()
    }

    get cancellable() {
        if (this.status === ApplicationStatus.Pending) {
            const start = this.appointmentStart ? new Date(this.appointmentStart) : undefined;
            const end = this.appointmentEnd ? new Date(this.appointmentEnd) : undefined;
            if (start && end) {
                const now = new Date();
                if (now < start) {
                    return true;
                }
                return false
            } else {
                return true;
            }
        }
        return false;
    }

    get isAppointmentDueNow() {
        try {
            // return true;
            if (this.status === ApplicationStatus.Pending) {
                const start = new Date(this.appointmentStart);
                const end = new Date(this.appointmentEnd);
                if (start && end) {
                    const now = new Date();
                    if (now >= start && now <= end) {
                        return true;
                    }
                    return false
                } else {
                    return false;
                }
            }
            return false;
        } catch (e) {
            Logger.error('Application.ts@isAppointmentDueNow Exception', e)
            return false;
        }
    }

    get appointmentTime() {
        if (this.appointmentStart) {
            return new Date(this.appointmentStart).toLocaleString()
        }
        return "N/A"
    }

    /** SELECTORS **/
    static selectApplications = createSelector([(state: RootState) => state.applications], (applicationsState) => {
        return applicationsState.data.map(application => new Application(application));
    })
    static selectApplicationsIsLoading = createSelector([(state: RootState) => state.applications], (applicationsState) => {
        return applicationsState.isLoading
    })
    static selectApplicationIsInProgress = createSelector([(state: RootState) => state.applications], (applicationsState) => {
        return applicationsState.data.some(application => nonTerminalStatuses.includes(application.status))
    })
    static selectApplicationUpcoming = createSelector([(state: RootState) => state.applications], (applicationsState) => {
        const applications = applicationsState.data.map(application => new Application(application));
        return applications.some(application => nonTerminalStatuses.includes(application.status) && application.cancellable)
    })
    static selectApplication = createSelector([(state: RootState) => state.applications], (applicationsState) => {
        return applicationsState.applicationData ? new Application(applicationsState.applicationData) : null;
    })
}

export default Application;
