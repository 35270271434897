import {css} from "aphrodite";
import styles from "./styles.js";
import Vidyo from "../../../Models/Vidyo/Vidyo.ts";
import {CameraSelectProps} from "./CameraSelectProps.ts";
import Form from "react-bootstrap/Form";

const CameraSelect = (props: CameraSelectProps) => {
    const {cameras, selectedCameraId, selectCameraById, disabled} = props
    if (cameras) {
        return (
            <Form.Group controlId={"formCameraSelect"} className={css(styles.deviceSelect)}>
                <Form.Label>Camera</Form.Label>
                <Form.Select disabled={disabled} name="cameraId" id={"cameraId"} size={"lg"}
                             value={selectedCameraId || ''} onChange={selectCameraById}>
                    {Object.keys(cameras)?.map(cameraId =>
                        <option value={cameraId} key={cameraId}>
                            {Vidyo.formatDeviceName(cameras[cameraId]?.name)}
                        </option>
                    )}
                </Form.Select>
            </Form.Group>
        )
    }
    return null;
}

export default CameraSelect
