import {css} from "aphrodite";
import styles from './styles.tsx';
import useTranslate from "../../Hooks/useTranslate.ts";
import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../State/hooks.ts";
import Application from "../../Models/Application/Application.ts";
import Button from "react-bootstrap/Button";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {object, string, number, date, ValidationError, boolean} from 'yup';
import {splitOnFirstWord} from "../../Utils/Helpers.ts";
import Logger from "../../Utils/Logger.ts";
import {ApplicationFormData, ApplicationFormErrors, TimeSlot} from '../../Types/ApplicationTypes.ts'
import {Notify} from "../../Utils/Notify.ts";
import {CustomModal, CustomModalProps} from "../components/CustomModal/CustomModal.tsx";
import {useNavigate} from "react-router-dom";
import User from "../../Models/User/User.ts";
import {countryList} from "../../Utils/countryHelper.ts";
import {fetchTimeSlots} from "../../Models/Application/applicationsAPI.ts";

type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
const defaultApplicationFormErrors = {
    documentType: '',
    documentNumber: '',
    documentIssueDate: '',
    documentExpiryDate: '',
    documentIssueCountry: '',
    documentFile: '',
    appointmentDate: '',
    startDate: '',
    termsAccepted: '',
}
const defaultModalProps: CustomModalProps = {
    title: '',
    show: false,
}
const NewApplicationPage = () => {
    const [validated, setValidated] = useState<boolean>(false);
    const [errors, setErrors] = useState<ApplicationFormErrors>({...defaultApplicationFormErrors});
    const [formData, setFormData] = useState<ApplicationFormData>({
        documentType: 0,
        documentNumber: '',
        documentIssueDate: '',
        documentExpiryDate: '',
        documentIssueCountry: '',
        documentFile: '',
        appointmentDate: '',
        startDate: '',
        termsAccepted: false,
    });
    const [modalProps, setModalProps] = useState<CustomModalProps>({...defaultModalProps})
    const [fetchApplicationsTrigger, setFetchApplicationsTrigger] = useState<boolean>(false);
    const [selectedFileName, setSelectedFileName] = useState<string>('');
    const [timeslots, setTimeslots] = useState<TimeSlot[]>([]);
    const [fetchingTimeslots, setFetchingTimeslots] = useState<boolean>(false);
    const navigate = useNavigate()
    const applicationIsLoading = useAppSelector(Application.selectApplicationsIsLoading)
    const upcomingApplication = useAppSelector(Application.selectApplicationUpcoming)
    const isHttpClientReady = useAppSelector(User.selectHttpClientIsReady);
    const {t} = useTranslate('translations');
    const todayDateString = new Date().toISOString().split('T')[0];
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const tomorrowDateString = tomorrowDate.toISOString().split('T')[0];

    useEffect(() => {
        if (isHttpClientReady) {
            setFetchApplicationsTrigger(true)
        }
    }, [isHttpClientReady]);
    useEffect(() => {
        if (fetchApplicationsTrigger) {
            Application.getApplications()
        }
    }, [fetchApplicationsTrigger]);
    useEffect(() => {
        if (upcomingApplication) {
            navigate('/', {replace: true})
        }
    }, [upcomingApplication]);
    useEffect(() => {
        handleAppointmentDateChange()
    }, [formData.appointmentDate]);
    const getValidationSchema = () => {
        const now = new Date();
        return {
            documentType: number().required().oneOf([0, 3], "documentType " + t('is a required field')),
            documentNumber: string().nullable().required(),
            documentIssueDate: date().required().transform(
                (dateString) => new Date(dateString))
                .max(new Date(now.getFullYear(), now.getMonth(), now.getDate())),
            documentExpiryDate: date().required().transform(
                (dateString) => new Date(dateString))
                .min(new Date(now.getFullYear(), now.getMonth(), now.getDate())),
            documentIssueCountry: string().required(),
            documentFile: string().required().test((x) => {
                return x.split(',')[0] === 'data:application/pdf;base64'
            }),
            appointmentDate: date().required().transform(
                (dateString) => new Date(dateString))
                .min(new Date(now.getFullYear(), now.getMonth(), now.getDate())),
            startDate: string().required(),
            termsAccepted: boolean().required().oneOf([true]),
        }
    }
    const validateForm = async () => {
        try {

            const applicationSchema = object().shape(
                getValidationSchema()
            );
            await applicationSchema.validate(formData, {
                abortEarly: false,
            })
            // console.log("isValid: " + isValid + ` (${typeof isValid})`, isValid)
            setErrors({...defaultApplicationFormErrors});
            setValidated(true)
            return true
        } catch (e) {
            if (e instanceof ValidationError) {
                const newErrors: ApplicationFormErrors = {...defaultApplicationFormErrors}
                for (const error of e.errors) {
                    const parts = splitOnFirstWord(error) // documentNumber field is required
                    if (parts.firstWord && Object.keys(formData).includes(parts.firstWord as keyof ApplicationFormData)) {
                        const key = parts.firstWord as keyof ApplicationFormErrors;
                        newErrors[key] = parts.restOfString;
                    }
                }
                console.log("newErrors: " + newErrors + ` (${typeof newErrors})`, newErrors)
                setErrors(newErrors);
            }
            setValidated(false)
            return false
        }
    }
    const validateField = async (fieldName: keyof ApplicationFormData, value: boolean | string | number) => {
        try {
            const schemas = getValidationSchema()
            const applicationSchema = object().shape(
                {
                    [fieldName]: schemas[fieldName]
                }
            );
            await applicationSchema.validate({[fieldName]: value}, {
                abortEarly: false,
            })
            // console.log("isValid: " + isValid + ` (${typeof isValid})`, isValid)
            setErrors({...errors, [fieldName]: ''});
            // setValidated(true)
        } catch (e) {
            if (e instanceof ValidationError) {
                const newErrors: ApplicationFormErrors = {...errors}
                for (const error of e.errors) {
                    const parts = splitOnFirstWord(error)
                    if (parts.firstWord && Object.keys(formData).includes(parts.firstWord as keyof ApplicationFormData)) {
                        const key = parts.firstWord as keyof ApplicationFormErrors;
                        newErrors[key] = parts.restOfString;
                    }
                }
                console.log("newErrors: " + newErrors + ` (${typeof newErrors})`, newErrors)
                setErrors({...errors, [fieldName]: newErrors[fieldName]});
            }
            // setValidated(false)
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const formValidated = await validateForm();
        if (formValidated) {
            setModalProps({
                title: t('Application Summary'),
                // onHide: () => setModalProps({...defaultModalProps}),
                onCancel: () => setModalProps({...defaultModalProps}),
                onConfirm: () => {
                    setModalProps({...defaultModalProps})
                    createApplication()
                },
                confirmText: t('Confirm'),
                cancelText: t('Cancel'),
                show: true,
                children: <div>
                    <p>{t('Review the information you have provided and click the confirm button to book your appointment')}</p>
                    <p>{t('Document Type')}: {formData.documentType !== undefined && ({
                        0: t('Passport'),
                        3: t('Resident Permit')
                    }[formData.documentType] ?? 'N/A')}</p>
                    <p>
                        {t('Document Number')}: {formData.documentNumber}
                    </p>
                    <p>
                        {t('Document Issue Date')}: {formData.documentIssueDate}
                    </p>
                    <p>
                        {t('Document Expiry Date')}: {formData.documentExpiryDate}
                    </p>
                    <p>
                        {t('Document Issue Country')}: {formData.documentIssueCountry}
                    </p>
                    <p>
                        {t('Document File')}: {selectedFileName}
                    </p>
                    <p>
                        {t('Appointment Date')}: {formData.appointmentDate}
                    </p>
                    <p>
                        {t('Appointment Hour')}: {formData.startDate}
                    </p>
                </div>
            })
        }

    }
    const createApplication = async () => {
        try {
            const result = await Application.createNewApplication(formData)
            if (result.success) {
                Notify(t('Application submitted successfully.'), "success", 4000)
                setFormData({
                    documentType: 0,
                    documentNumber: '',
                    documentIssueDate: '',
                    documentExpiryDate: '',
                    documentIssueCountry: '',
                    documentFile: '',
                    appointmentDate: '',
                    startDate: '',
                    termsAccepted: false,
                })
                setErrors({...defaultApplicationFormErrors})
                setModalProps({
                    title: t('Application submitted successfully'),
                    // onHide: () => setModalProps({...defaultModalProps}),
                    // onCancel: () => setModalProps({...defaultModalProps}),
                    onConfirm: () => {
                        setModalProps({...defaultModalProps})
                        navigate('/', {replace: true})
                    },
                    confirmText: t('Ok'),
                    cancelText: t('Close'),
                    show: true,
                    children: <div>
                        <p>{t('Your application has been submitted successfully.')}</p>
                        <p>{t('You will receive an email confirmation shortly.')}</p>
                        <p>{t('If you do not receive an email confirmation within 24 hours, please contact us at ') + "support@example.com"}</p>
                    </div>
                })
            }
            Logger.console("result", result)

        } catch (e) {
            if (e instanceof Error) {
                Logger.error("NewApplicationPage@handleSubmit Exception", e.message)
            } else {
                Logger.error("NewApplicationPage@handleSubmit Exception", e)
            }
            Notify(t('There was an error submitting the form. Please try again later.'), "error")
        }
    }
    const handleChange = (event: React.ChangeEvent<FormControlElement>) => {
        const {name, value, type, checked, files} = event.target as HTMLInputElement;
        if (type === 'file') {
            let newValue: string = ''
            if (files && files.length > 0) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    newValue = reader.result as string;
                    validateField(name as keyof ApplicationFormData, newValue)
                    setFormData({
                        ...formData,
                        [name]: reader.result as string
                    });
                };
                setSelectedFileName(files[0].name);
                reader.readAsDataURL(files[0]);
            } else {
                setSelectedFileName('');
                validateField(name as keyof ApplicationFormData, newValue)
            }
        } else {
            let newValue: string | number | boolean = value
            const typeOf = typeof formData[name as keyof ApplicationFormData]
            if (typeOf === 'number') {
                newValue = Number(value);
            } else if (typeOf === 'boolean') {
                newValue = checked;
            }
            setFormData({
                ...formData,
                [name]: newValue
            });
            validateField(name as keyof ApplicationFormData, newValue)
        }
    };
    const handleAppointmentDateChange = () => {

        const selectedDate = formData.appointmentDate
        if (selectedDate && selectedDate >= tomorrowDateString) {
            getTimeSlots(selectedDate)
        }
    }

    const getTimeSlots = async (date: string) => {
        setFetchingTimeslots(true)
        setFormData({
            ...formData,
            startDate: ''
        });
        setTimeslots([])
        try {
            const timeslotsResponse = await fetchTimeSlots(date + "T00:00:00.000Z", date + "T23:59:59.999Z")
            if (timeslotsResponse.success && timeslotsResponse.data?.[date]) {
                console.log("setting timeslots to ", timeslotsResponse.data[date])
                setTimeslots(timeslotsResponse.data[date])
            } else {
                Notify(t('There was an error fetching the timeslots. Please try again'), "error", 4000)
            }
        } catch (e) {
            Notify(t('There was an error fetching the timeslots. Please try again'), "error", 4000)
            Logger.error("NewApplicationPage@getTimeSlots Exception", e)
        } finally {
            setFetchingTimeslots(false)
        }
    }

    return (
        <div className={css(styles.pageContainer)}>
            <Container className={css(styles.parentContainer)}>
                <Row className={css(styles.container)}>
                    <Col xs={12}>
                        <h3 className="text-center mb-3">{t('New Application')}</h3>
                        <Form noValidate={true} validated={validated} onSubmit={handleSubmit}>
                            <Form.Label column={"lg"}>{t('Document Type')}</Form.Label>
                            <Form.Group className="mb-3" controlId="formDocumentType">
                                <Form.Check type="radio" label={t('Passport')} name={"documentType"} inline
                                            className={css(styles.radio)}
                                            value={0} required checked={formData.documentType === 0}
                                            onChange={handleChange}/>
                                <Form.Check type="radio" label={t('Resident Permit')} name={"documentType"} inline
                                            className={css(styles.radio)}
                                            value={3} required checked={formData.documentType === 3}
                                            onChange={handleChange}/>
                                {errors['documentType'] && (
                                    <Form.Text className={css(styles.inputError)} id="documentTypeHelpBlock" muted>
                                        {t('Document Type')} {t(errors['documentType'])}
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formDocumentNumber">
                                <Form.Label column={"lg"}>{t('Document Number')}</Form.Label>
                                <Form.Control type="text" className={css(styles.input)} name={"documentNumber"}
                                              placeholder={t("Enter") + " " + t('DocumentType' + (formData.documentType || '') + 'Number')}
                                              value={formData.documentNumber || ''} readOnly={applicationIsLoading}
                                              required
                                              onChange={handleChange}/>
                                {errors['documentNumber'] && (
                                    <Form.Text className={css(styles.inputError)} id="documentNumberHelpBlock"
                                               muted>
                                        {t('Document Number')} {t(errors['documentNumber'])}
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formDocumentIssueDate">
                                <Form.Label column={"lg"}>{t('Document Issue Date')}</Form.Label>
                                <Form.Control type="date" className={css(styles.input)} name={"documentIssueDate"}
                                              max={todayDateString}
                                              placeholder={t("Enter") + " " + t('DocumentType' + (formData.documentType || '') + 'IssueDate')}
                                              value={formData.documentIssueDate || ''}
                                              readOnly={applicationIsLoading}
                                              required
                                              onChange={handleChange}/>
                                {errors['documentIssueDate'] && (
                                    <Form.Text className={css(styles.inputError)} id="documentIssueDateHelpBlock"
                                               muted>
                                        {t('Document Issue Date')} {t(errors['documentIssueDate'])}
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formDocumentExpiryDate">
                                <Form.Label column={"lg"}>{t('Document Expiry Date')}</Form.Label>
                                <Form.Control type="date" className={css(styles.input)} name={"documentExpiryDate"}
                                              min={todayDateString}
                                              placeholder={t("Enter") + " " + t('DocumentType' + (formData.documentType || '') + 'ExpiryDate')}
                                              value={formData.documentExpiryDate || ''}
                                              readOnly={applicationIsLoading}
                                              required
                                              onChange={handleChange}/>
                                {errors['documentExpiryDate'] && (
                                    <Form.Text className={css(styles.inputError)} id="documentExpiryDateHelpBlock"
                                               muted>
                                        {t('Document Expiry Date')} {t(errors['documentExpiryDate'])}
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formDocumentIssueCountry">
                                <Form.Label column={"lg"}>{t('Document Issue Country')}</Form.Label>
                                <Form.Select className={css(styles.input)} name={"documentIssueCountry"}
                                             value={formData.documentIssueCountry || ''}
                                             disabled={applicationIsLoading}
                                             required
                                             onChange={handleChange}>
                                    <option value={''} disabled={true}>
                                        {t("Enter") + " " + t('DocumentType' + (formData.documentType || '') + 'IssueCountry')}
                                    </option>
                                    {countryList.map((country, index) =>
                                        <option value={country} key={index}>
                                            {t(country)}
                                        </option>)}
                                </Form.Select>
                                {errors['documentIssueCountry'] && (
                                    <Form.Text className={css(styles.inputError)} id="documentIssueCountryHelpBlock"
                                               muted>
                                        {t('Document Issue Country')} {t(errors['documentIssueCountry'])}
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group controlId="formDocument" className="mb-3">
                                <Form.Label
                                    column={"lg"}>{t('DocumentType' + (formData.documentType !== undefined ? formData.documentType : '') + 'File')}</Form.Label>
                                <Form.Control type="file" name={"documentFile"} size={"lg"} onChange={handleChange}
                                              accept={"application/pdf"} required
                                              disabled={applicationIsLoading}/>
                                {errors['documentFile'] && (
                                    <Form.Text className={css(styles.inputError)} id="documentIssueCountryHelpBlock"
                                               muted>
                                        {t('Document File')} {t(errors['documentFile'])}
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3 w-100" controlId="formAppointmentDate">
                                        <Form.Label column={"lg"}>{t('Choose Date')}</Form.Label>
                                        <Form.Control type="date" className={css(styles.input)}
                                                      name={"appointmentDate"}
                                                      min={tomorrowDateString}
                                                      placeholder={t("Enter") + " " + t('DocumentType' + (formData.documentType || '') + 'Appointment Date')}
                                                      value={formData.appointmentDate || ''}
                                                      readOnly={applicationIsLoading}
                                                      required
                                                      onChange={handleChange}/>
                                        {errors['appointmentDate'] && (
                                            <Form.Text className={css(styles.inputError)}
                                                       id="appointmentDateHelpBlock"
                                                       muted>
                                                {t('Appointment Date')} {t(errors['appointmentDate'])}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                    {/*<div className={css(styles.inputGroupSpacer)}></div>*/}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3 w-100" controlId="formstartDate">
                                        <Form.Label column={"lg"}>{t('Choose an available hour')}</Form.Label>
                                        {fetchingTimeslots ? (
                                                <div className={css(styles.spinnerContainer)}>
                                                    <Spinner as="span" animation="border"
                                                             role="status"
                                                             aria-hidden="true"
                                                             variant="primary"
                                                    />
                                                </div>
                                            ) :
                                            <Form.Select className={css(styles.input)} name={"startDate"}
                                                         value={formData.startDate || ''}
                                                         disabled={applicationIsLoading}
                                                         required
                                                         onChange={handleChange}>
                                                <option className={css(styles.optionItem)} value={''}
                                                        disabled={!!formData.startDate}>
                                                    {'-'}
                                                </option>
                                                {timeslots.map((timeslot, index) => {
                                                    return <option className={css(styles.optionItem)}
                                                                   value={timeslot.startTime}
                                                                   key={index}>
                                                        {new Date(timeslot.startTime).toLocaleTimeString('el-GR', {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        })} - {new Date(timeslot.endTime).toLocaleTimeString('el-GR', {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })}
                                                    </option>
                                                })}
                                            </Form.Select>
                                        }
                                        {errors['startDate'] && (
                                            <Form.Text className={css(styles.inputError)}
                                                       id="startDateHelpBlock"
                                                       muted>
                                                {t('Appointment Hour')} {t(errors['startDate'])}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className="mb-5" controlId="formAcceptTerms">
                                <Form.Check type="checkbox" label={t('Accept Terms and Conditions')}
                                            name={"termsAccepted"}
                                            inline value={1}
                                            className={css(styles.radio)}
                                            required checked={formData.termsAccepted === true}
                                            onChange={handleChange}/>
                                {errors['termsAccepted'] && (
                                    <Form.Text className={css(styles.inputError)} id="termsAcceptedHelpBlock" muted>
                                        {t('You must accept the terms and conditions')}
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3 d-flex justify-content-center" controlId="formSubmit">
                                <Button type={"submit"} className={css(styles.loginButton)}
                                        disabled={applicationIsLoading}>
                                    {applicationIsLoading ? <Spinner
                                        as="span"
                                        animation="border"
                                        // size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : <div className={css(styles.buttonLabel)}>{t('Submit')}</div>}
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>

                </Row>
            </Container>

            <CustomModal {...modalProps}>
                {modalProps.children}
            </CustomModal>
        </div>
    )
}
export {NewApplicationPage}
