import {css} from 'aphrodite';
import styles from './styles';
import UserMenu from "../UserMenu/UserMenu.tsx";
import {useAppSelector} from "../../../../../State/hooks.ts";
import User from "../../../../../Models/User/User.ts";
import ukFlag from '../../../Icons/united-kingdom.png'
import grFlag from '../../../Icons/greece.png'
// import useTranslate from "../../../../../Hooks/useTranslate.ts";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const Menu = () => {
    const authUser = useAppSelector(User.selectUser)
    // const {t} = useTranslate(['translations'])
    const {i18n} = useTranslation()
    useEffect(() => {
        console.log("i18n lang", i18n.language)
    }, [])
    const changeLanguage = () => {
        i18n.changeLanguage(i18n.language === 'en' ? 'el' : 'en')
    }
    return (
        <div className={css(styles.container)}>
            <div className={css(styles.text)}>
                {authUser?.email}
            </div>
            <img className={css(styles.flag)} src={i18n.language === 'en' ? grFlag : ukFlag} alt="lang"
                 onClick={changeLanguage}/>
            <UserMenu/>
        </div>
    );
};

export {Menu};
