import {css} from "aphrodite";
import styles from "./styles.tsx";
import Application from "../../../../Models/Application/Application.ts";
import {ApplicationStatusNames} from "../../../../Utils/Enumerations.ts";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useAppSelector} from "../../../../State/hooks.ts";
import useTranslate from "../../../../Hooks/useTranslate.ts";

interface ApplicationItemProps {
    application: Application;
    onClick?: (applicationId: string) => void;
    onCancel?: (applicationId: string) => void;
}

const ApplicationItem = (props: ApplicationItemProps) => {
    const {application} = props
    const applicationsIsLoading = useAppSelector(Application.selectApplicationsIsLoading);
    const {t} = useTranslate(['translations']);
    const handleOnClick = () => {
        props.onClick && props.onClick(application.id)
    }

    const handleCancelApplication = () => {
        props.onCancel && props.onCancel(application.id)
    }

    return (
        <Container
            className={css(styles.applicationContainer)}
        >
            <Row>
                <Col xs={12} sm={9} className={css(styles.application)}>
                    <Row>
                        <Col xs={12} sm={5} className={"d-flex align-items-center"}>
                            <Button
                                className={css(styles.titleButton)}
                                variant="link"
                                color="primary"
                                disabled={!application.isAppointmentDueNow}
                                onClick={handleOnClick}
                            >
                                <h5>{t('Application') + ":  " + application.created}</h5>
                            </Button>
                        </Col>
                        <Col xs={12} sm={7}>
                            <div className={"d-flex align-items-start text-md-start"}>
                                <span className={css(styles.textBold)}>{t('Start')}</span>: {application.startsAt}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={5} className={"d-flex align-items-center"}>
                            <h6>
                                <span
                                    className={css(styles.textBold)}>{t('Status')}</span>: {t(ApplicationStatusNames[application.status])}
                            </h6>
                        </Col>
                        <Col xs={12} sm={7} className={"d-flex align-items-center justify-content-center"}>
                            <div className={"d-flex align-items-start text-md-start"}>
                                <span className={css(styles.textBold)}>{t('End')}</span>: {application.endsAt}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={3}
                     className={"d-flex align-items-center justify-content-sm-end justify-content-center"}>
                    <Button
                        disabled={!application.cancellable || applicationsIsLoading}
                        variant="outline-danger text-nowrap"
                        onClick={handleCancelApplication}
                    >
                        {t('Cancel')}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
export {ApplicationItem}
