import {css} from "aphrodite";
import styles from './styles.tsx';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import {confirmUserEmail} from "../../Models/User/userAPI.ts";
import useTranslate from "../../Hooks/useTranslate.ts";
import Logger from "../../Utils/Logger.ts";
import {Notify} from "../../Utils/Notify.ts";
import {useAppSelector} from "../../State/hooks.ts";
import User from "../../Models/User/User.ts";

const AccountActivationPage = () => {
    const params = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const httpClientReady = useAppSelector(User.selectHttpClientIsReady)
    const {t} = useTranslate(['translations']);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("params", params)
        if (params.userId && params["*"] && httpClientReady) {
            console.log("token", params["*"])
            console.log("userId", params.userId)
            confirmEmail(params.userId, params["*"])
        } else {
            navigate('/login')
        }
    }, [params, httpClientReady]);
    const confirmEmail = async (userId: string, token: string) => {
        try {
            const result = await confirmUserEmail({
                userId: userId,
                token: token
            })
            if (result.success && result.data === 'confirmed') {
                Notify(t('Email confirmed'), 'success', 4000)
                setError('')
                navigate('/login')
            } else {
                setError(result.message)
                setIsLoading(false)
            }
        } catch (e) {
            Logger.error("AccountActivationPage@confirmEmail() Exception: ", e)
            if (e instanceof Error) {
                setError(e.message)
            } else {
                setError(String(e))
            }
            setIsLoading(false)
        }
    }
    return (
        <div className={css(styles.pageContainer)}>
            {isLoading &&
                <Spinner animation="grow" role="status" variant="primary"></Spinner>
            }
            {error && (
                <div>{t('Email confirmation failed')}</div>
            )}
        </div>
    )
}
export {AccountActivationPage}
