import {useState} from "react";
import {Container, Row, Col, Button, ListGroup, Card} from "react-bootstrap";
import {CameraVideo, CameraVideoOff, Mic, MicMute, Person, Wifi} from "react-bootstrap-icons";
import {Notify} from "../../../Utils/Notify";
import useTranslate from "../../../Hooks/useTranslate";
import Logger from "../../../Utils/Logger.ts";

const ReviewPermissions = (props: { onProceed: () => void }) => {
    const {onProceed} = props
    const [gotCameraPermission, setGotCameraPermission] = useState(false);
    const [gotMicrophonePermission, setGotMicrophonePermission] = useState(false);
    const {t} = useTranslate('translations');
    // const navigate = useNavigate();

    const microphoneCheck = async () => {
        try {
            const stream = await navigator?.mediaDevices?.getUserMedia({audio: true});
            if (stream) {
                setGotMicrophonePermission(true);
                stream?.getAudioTracks()[0]?.stop();
            }
        } catch (e) {
            Notify(t("No microphone found. Connect the microphone to continue"), "error", 4000);
            console.log(e);
        }
    };

    const cameraCheck = async () => {
        try {
            const stream = await navigator?.mediaDevices?.getUserMedia({
                video: {
                    width: {ideal: 1280},
                    height: {ideal: 720},
                    facingMode: "user",
                },
            });
            const track = stream?.getVideoTracks()[0];
            const settings = track?.getSettings();
            Logger.console("camera settings", settings)
            if (settings?.width && settings?.height) {
                if ((settings?.width >= 1280 && settings?.height >= 720) || (settings?.width >= 720 && settings?.height >= 1280)) {
                    setGotCameraPermission(true);
                } else {
                    Notify(t("Camera resolution must be at least 1280x720 pixels"), "error", 4000);

                }
            } else {
                setGotCameraPermission(false);
            }


            track?.stop();
            stream?.getVideoTracks()[0]?.stop();
        } catch (e) {
            Notify(t("No camera found. Connect the camera to continue"), "error", 4000);
            console.log(e);
        }
    };

    const proceedVideoSession = async () => {
        if (onProceed && typeof onProceed === "function") {
            onProceed()
        }
    };

    const isReadyToProceed = gotMicrophonePermission && gotCameraPermission;

    return (
        <Container className="mt-4" style={{maxWidth: 600}}>
            <h1 className="mb-3">{t("Review Permissions")}</h1>
            <p>
                {t("Please ensure you have granted the necessary permissions to proceed with the video session")}
            </p>

            <Row className="mb-3">
                <Col>
                    <Button
                        variant={gotMicrophonePermission ? "success" : "outline-primary"}
                        onClick={microphoneCheck}
                    >
                        {gotMicrophonePermission ? (
                            <>
                                <Mic className="me-2"/> {t("Microphone Enabled")}
                            </>
                        ) : (
                            <>
                                <MicMute className="me-2"/>
                            </>
                        )}
                    </Button>
                </Col>
                <Col>
                    <Button
                        variant={gotCameraPermission ? "success" : "outline-primary"}
                        onClick={cameraCheck}
                    >
                        {gotCameraPermission ? (
                            <>
                                <CameraVideo className="me-2"/> {t("Camera Enabled")}
                            </>
                        ) : (
                            <>
                                <CameraVideoOff className="me-2"/>
                            </>
                        )}
                    </Button>
                </Col>
            </Row>
            {isReadyToProceed && (
                <Card className="mb-3">
                    <Card.Body>
                        <Card.Title>{t("Before starting the live video, make sure:")}</Card.Title>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <Person className="me-2"/>
                                {t("Not wearing sunglasses or a hat")}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Person className="me-2"/>
                                {t("You are alone in a quiet, well-lit place")}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Wifi className="me-2"/>
                                {t("You have a strong internet connection")}
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
            )}
            <Button
                className="mt-3"
                variant="primary"
                onClick={proceedVideoSession}
                disabled={!isReadyToProceed}
            >
                {t("Proceed to Video Session")}
            </Button>
        </Container>
    );
};

export default ReviewPermissions;
