import Logger from "./Logger.ts";

const isBlank = (str: string) => {
    return /^\s*$/.test(str);
};
const isJSON = (str: string) => {
    if (isBlank(str)) {
        return false;
    }
    str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
    str = str.replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        ']',
    );
    str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
    return /^[\],:{}\s]*$/.test(str);
};
const isObject = (objValue: unknown) => {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}
const parseJwt = (token: string) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    } catch (e) {
        Logger.console("parseJwt Exception", e)
        return null
    }
};

interface DeviceDetailsType {
    os: string;
    browser: string;
    isMobile: boolean;
}

const getDeviceDetails = (userAgent: string): DeviceDetailsType => {
    userAgent = userAgent.toLowerCase();

    // Determine the operating system
    let os = "Unknown OS";
    if (/windows nt/.test(userAgent)) {
        os = "Windows";
    } else if (/android/.test(userAgent)) {
        os = "Android";
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
        os = "iOS";
    } else if (/mac os/.test(userAgent)) {
        os = "Mac OS";
    } else if (/linux/.test(userAgent)) {
        os = "Linux";
    }

    // Determine the browser
    let browser = "Unknown Browser";
    if (/chrome/.test(userAgent) && !/edge/.test(userAgent)) {
        browser = "Chrome";
    } else if (/safari/.test(userAgent) && !/chrome/.test(userAgent)) {
        browser = "Safari";
    } else if (/firefox/.test(userAgent)) {
        browser = "Firefox";
    } else if (/edge/.test(userAgent)) {
        browser = "Edge";
    } else if (/opr/.test(userAgent) || /opera/.test(userAgent)) {
        browser = "Opera";
    }

    // Determine if the device is mobile
    const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

    return {os, browser, isMobile};
};

interface SplitOnFirstWordType {
    firstWord: string;
    restOfString: string;
}

const splitOnFirstWord = (inputString: string): SplitOnFirstWordType => {
    try {
        const match = inputString.match(/\b\w+\b/);
        if (match) {
            const firstWord = match[0];
            const restOfString = inputString.slice(inputString.indexOf(firstWord) + firstWord.length).trim();
            return {
                firstWord: firstWord,
                restOfString: restOfString
            };
        }
        return {
            firstWord: '',
            restOfString: inputString
        };
    } catch (e) {
        if (e instanceof Error) {
            Logger.error("splitOnFirstWord Error", e)
        }
        return {
            firstWord: '',
            restOfString: ''
        };
    }
}
export {
    isBlank,
    isJSON,
    isObject,
    parseJwt,
    splitOnFirstWord,
    getDeviceDetails,
}
export type {DeviceDetailsType}
