import callStart from '../components/Icons/callStart.png'
import callEnd from '../components/Icons/callEnd.png'
import Vidyo from "../../Models/Vidyo/Vidyo.ts";
import {vidyoConnectorState} from "../../Models/Vidyo/VidyoEnumerations.ts";
import Logger from "../../Utils/Logger.ts";
import React, {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../State/hooks.ts";
import Application from "../../Models/Application/Application.ts";
import User from "../../Models/User/User.ts";
import {css} from "aphrodite";
import styles from "./styles.ts";
import {Notify} from "../../Utils/Notify.ts";
import {useNavigate} from "react-router-dom";
import {OtpModal} from "./OtpModal/OtpModal.tsx";
import Navigator from "../../Models/Navigator/Navigator.ts"
import {CustomModal} from "../components/CustomModal/CustomModal.tsx";
import CameraSelect from "../components/CameraSelect/CameraSelect.tsx";
import {FormControlElement} from "../../Types/GenericTypes.ts";
import cameraOn from '../components/Icons/cameraOn.png'
import cameraOff from '../components/Icons/cameraOff.png'
import micOn from '../components/Icons/microphoneOn.png'
import micOff from '../components/Icons/microphoneOff.png'
import speakerOn from '../components/Icons/speakerOn.png'
import speakerOff from '../components/Icons/speakerOff.png'
import settingsIcon from '../components/Icons/setting.png'
import MicrophoneSelect from "../components/MicrophoneSelect/MicrophoneSelect.tsx";
import SpeakerSelect from "../components/SpeakerSelect/SpeakerSelect.tsx";

const VideoRoomPage = () => {
    const [initTrigger, setInitTrigger] = useState<boolean>(false);
    const [hasVidyoState, setHasVidyoState] = useState(false)
    const vidyoRef = useRef<Vidyo>()
    const vidyo = useAppSelector(Vidyo.selectVidyo);
    const application = useAppSelector(Application.selectApplication);
    const user = useAppSelector(User.selectUser);
    const showVideoSettingsModal = useAppSelector(Navigator.selectShowVideoSettingsModal);
    const navigate = useNavigate()
    const showOtpModal = useAppSelector(Navigator.selectShowOtpModal)
    useEffect(() => {
        if (user && application?.isAppointmentDueNow && application.workflowId && application.videoSessionId) {
            setInitTrigger(true)
        } else {
            navigate("/")
        }
    }, [application, user]);
    useEffect(() => {
        if (initTrigger) {
            initializeVidyoClient()
        }
    }, [initTrigger]);
    useEffect(() => {
        Logger.info("Vidyo Redux", vidyo)
    }, [vidyo]);
    useEffect(() => {
        if (vidyo) {
            if (vidyo.state && !hasVidyoState) {
                setHasVidyoState(true)
            }
        } else {
            if (hasVidyoState) {
                setHasVidyoState(false)
            }
        }
        if (vidyo?.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = () => {
            };
        }
    }, [vidyo?.state])
    const toggleJoinLeaveConference = async () => {
        if (vidyo?.roomKey) {
            if (await vidyoRef.current?.isConnected()) {
                vidyoRef.current?.disconnect()
            } else {
                await vidyoRef.current?.connectToConference()
            }
        } else {
            Notify("Room Key not provided", "error")
        }
    }
    const initializeVidyoClient = async () => {
        if (user && application && application.workflowId && application.videoSessionId) {
            vidyoRef.current = new Vidyo({
                roomKey: application.videoSessionId,
                displayName: user.firstName + " " + user.lastName,
            })
            if (vidyoRef.current &&
                (vidyoRef.current.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Idle ||
                    vidyoRef.current.state === vidyoConnectorState.Ready)) {
                vidyoRef.current.initializeVidyoConnector()
            }
            Logger.console("vidyoRef", vidyoRef.current);
        }
    }
    const handleOtpSubmit = (otp: string) => {
        Logger.console("Submitted OTP: ", otp)
        vidyoRef.current?.sendPrivateChatMessage(otp)
        Navigator.setShowOtpModal(false)
    }
    const selectCameraById = (event: React.ChangeEvent<FormControlElement>) => {
        vidyoRef.current?.selectCameraById(event.target.value)
    }
    const selectMicrophoneById = (event: React.ChangeEvent<FormControlElement>) => {
        vidyoRef.current?.selectMicById(event.target.value)
    }
    const selectSpeakerById = (event: React.ChangeEvent<FormControlElement>) => {
        vidyoRef.current?.selectSpeakerById(event.target.value)
    }
    const toggleCamera = () => {
        if (vidyo?.isLocalCameraEnabled) {
            Logger.console('stopping camera')
            stopCamera()
        } else {
            Logger.console('starting camera')
            // if (!isModalOpen) {
            //     setIsModalOpen(true)
            // }
            // if (!showModalContent) {
            //     setShowModalContent(true)
            // }
            startCamera()
        }
    }
    const stopCamera = () => {
        vidyoRef.current?.stopCamera()
        vidyoRef.current?.hideLocalCameraView()
    }
    const startCamera = () => {
        vidyoRef.current?.startCamera()
    }
    const toggleMicrophone = () => {
        if (vidyo?.isLocalMicrophoneEnabled) {
            vidyoRef.current?.stopMic()
        } else {
            vidyoRef.current?.startMic()
        }
    }
    const toggleSpeaker = () => {
        if (vidyo?.isLocalSpeakerEnabled) {
            vidyoRef.current?.stopSpeaker()
        } else {
            vidyoRef.current?.startSpeaker()
        }
    }
    return (
        <div className={css(styles.container)}>
            <div className={css(styles.localVideoRendererContainer)}>
                <div id={import.meta.env.VITE_VIDYO_LOCAL_CAMERA_VIEW_ID}
                     className={css(styles.localVideoRenderer)}></div>
            </div>
            {vidyo?.participant && <div className={css(styles.remoteVideoRendererContainer)}>
                <div id={import.meta.env.VITE_VIDYO_REMOTE_CAMERA_VIEW_ID}
                     className={css(styles.remoteVideoRenderer)}></div>
            </div>}
            <div className={css(styles.toolbar)}>
                {(vidyo?.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected) && (
                    <button onClick={() => Navigator.setShowOtpModal(true)} className={css(styles.otpButton)}>
                        OTP
                    </button>
                )}
                <button onClick={() => Navigator.setShowVideoSettingsModal(true)} className={css(styles.otpButton)}>
                    <img src={settingsIcon} alt="settingsIcon" className={css(styles.footerToolbarButtonIcon)}/>
                </button>
                {<button onClick={toggleJoinLeaveConference} className={css(styles.rendererButton)}>
                    <img
                        src={vidyo?.state === vidyoConnectorState.VIDYO_CONNECTORSTATE_Connected ? callEnd : callStart}
                        alt="callIcon"
                        className={css(styles.footerToolbarButtonIcon)}/>
                </button>}
            </div>
            <OtpModal show={showOtpModal} onHide={() => Navigator.setShowOtpModal(false)} onConfirm={handleOtpSubmit}
                      onCancel={() => Navigator.setShowOtpModal(false)}/>
            {showVideoSettingsModal &&
                <CustomModal show={showVideoSettingsModal} title={'Video/Audio Settings'}
                             onCancel={() => Navigator.setShowVideoSettingsModal(false)}
                             onHide={() => Navigator.setShowVideoSettingsModal(false)} cancelText={'Close'}>
                    <div className={css(styles.footerToolbar)}>
                        {hasVidyoState &&
                            <div className={css(styles.settingsContainer)}>
                                <div className={css(styles.settingRow)}>
                                    <CameraSelect cameras={vidyoRef.current?.cameras}
                                                  selectedCameraId={vidyoRef.current?.selectedCameraId}
                                                  selectCameraById={selectCameraById} disabled={false}/>
                                    <button onClick={toggleCamera}
                                            className={css(vidyo?.isLocalCameraEnabled ? styles.footerToolbarButtonEnabled : styles.footerToolbarButton)}>
                                        <img src={vidyo?.isLocalCameraEnabled ? cameraOn : cameraOff}
                                             alt="cameraIcon"
                                             className={css(styles.footerToolbarButtonIcon)}/>
                                    </button>
                                </div>
                                <div className={css(styles.settingRow)}>
                                    <MicrophoneSelect microphones={vidyoRef.current?.microphones}
                                                      selectedMicrophoneId={vidyoRef.current?.selectedMicrophoneId}
                                                      selectMicrophoneById={selectMicrophoneById} disabled={false}/>
                                    <button onClick={toggleMicrophone}
                                            className={css(vidyo?.isLocalMicrophoneEnabled ? styles.footerToolbarButtonEnabled : styles.footerToolbarButton)}>
                                        <img src={vidyo?.isLocalMicrophoneEnabled ? micOn : micOff} alt="micIcon"
                                             className={css(styles.footerToolbarButtonIcon)}/>
                                    </button>
                                </div>
                                <div className={css(styles.settingRow)}>
                                    <SpeakerSelect speakers={vidyoRef.current?.speakers}
                                                   selectedSpeakerId={vidyoRef.current?.selectedSpeakerId}
                                                   selectSpeakerById={selectSpeakerById} disabled={false}/>
                                    <button onClick={toggleSpeaker}
                                            className={css(vidyo?.isLocalSpeakerEnabled ? styles.footerToolbarButtonEnabled : styles.footerToolbarButton)}>
                                        <img src={vidyo?.isLocalSpeakerEnabled ? speakerOn : speakerOff}
                                             alt="speakerIcon" className={css(styles.footerToolbarButtonIcon)}/>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </CustomModal>
            }
        </div>
    )
}

export default VideoRoomPage;
