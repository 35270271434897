import {useAppSelector} from "../../State/hooks.ts";
import User from "../../Models/User/User.ts";
import {useEffect, useState} from "react";
import Application from "../../Models/Application/Application.ts";
import {css} from "aphrodite";
import styles from './styles.tsx';
import {ApplicationItem} from "./components/ApplicationItem/ApplicationItem.tsx";
import Button from "react-bootstrap/Button";
import useTranslate from "../../Hooks/useTranslate.ts";
import {useNavigate} from "react-router-dom";
import {Notify} from "../../Utils/Notify.ts";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {CustomModalProps} from "../components/CustomModal/CustomModalProps.ts";
import {CustomModal} from "../components/CustomModal/CustomModal.tsx";
import Logger from "../../Utils/Logger.ts";

const defaultModalProps: CustomModalProps = {
    title: '',
    show: false,
    isLoading: true,
}

const HomePage = () => {
    // const user = useAppSelector(User.selectUser);
    const applications = useAppSelector(Application.selectApplications);
    const applicationsIsLoading = useAppSelector(Application.selectApplicationsIsLoading);
    const upcomingApplication = useAppSelector(Application.selectApplicationUpcoming)
    const isHttpClientReady = useAppSelector(User.selectHttpClientIsReady);
    const [fetchApplicationsTrigger, setFetchApplicationsTrigger] = useState<boolean>(false);
    const [modalProps, setModalProps] = useState<CustomModalProps>({...defaultModalProps})
    const navigate = useNavigate();
    const {t} = useTranslate('translations');
    useEffect(() => {
        if (!applicationsIsLoading && isHttpClientReady) {
            setFetchApplicationsTrigger(true)
        }
    }, [isHttpClientReady]);
    useEffect(() => {
        if (fetchApplicationsTrigger && !applicationsIsLoading) {
            Application.getApplications()
        }
    }, [fetchApplicationsTrigger]);
    useEffect(() => {
        console.log("applications", applications)
    }, [applications]);
    const handleNewApplicationClick = () => {
        if (upcomingApplication) {
            Notify(t('You cannot create a new application while another application is in progress'), 'error', 4000)
        } else {
            navigate('/new-application')
        }
    }
    const cancelApplication = async (application: Application) => {
        try {
            const result = await application.cancel()
            if (result?.success) {
                Notify(t('Application cancelled successfully'), 'success', 4000)
                setFetchApplicationsTrigger(true)
            } else {
                Notify(t('Application cancellation failed'), 'error', 4000)
            }
        } catch (e) {
            Logger.error("HomePage@cancelApplication() Exception: ", e)
            Notify(t('Application cancellation failed'), 'error', 4000)
        }
    }
    const handleCancelApplicationClick = (applicationId: string) => {
        const application = applications.find(app => app.id === applicationId)
        if (application?.cancellable) {
            setModalProps({
                title: t('Cancel Application'),
                // onHide: () => setModalProps({...defaultModalProps}),
                onCancel: () => setModalProps({...defaultModalProps}),
                onConfirm: async () => {
                    await cancelApplication(application)
                    setModalProps({...defaultModalProps})

                },
                confirmText: t('Cancel'),
                cancelText: t('Close'),
                show: true,
                children: <div>
                    <p>{t('Are you sure you want to cancel this application?')}</p>
                </div>
            })
        }
    }
    const handleAttendAppointmentClick = (applicationId: string) => {
        const application = applications.find(app => app.id === applicationId)
        if (application?.isAppointmentDueNow) {
            navigate('/Appointment/' + application.id)
        }
    }
    return (
        <div className={css(styles.pageContainer)}>
            <div className={css(styles.container)}>
                <div className={css(styles.applicationsContainer)}>
                    <div className="d-flex justify-content-between align-items-center flex-row w-100">
                        <h2>{t('Applications')}</h2>
                        <OverlayTrigger
                            trigger={upcomingApplication ? ['hover', 'focus'] : []}
                            placement="right"
                            delay={{show: 250, hide: 400}}
                            overlay={(
                                <Popover id="popover-basic" show={upcomingApplication} style={{position: "fixed"}}>
                                    <Popover.Header as="h3">{t('Warning') + '!'}</Popover.Header>
                                    <Popover.Body>
                                        {t('You cannot create a new application while another application is in progress')}
                                    </Popover.Body>
                                </Popover>
                            )}
                        >
                            <div className={css(styles.newApplicationContainer)}>
                                <Button className={css(styles.newApplicationButton)} variant="outline-primary"
                                        disabled={upcomingApplication} onClick={handleNewApplicationClick}>
                                    {t('New Application')}
                                </Button>
                            </div>
                        </OverlayTrigger>

                    </div>
                    <div className={css(styles.border)}></div>
                    <div className={css(styles.applicationListContainer)}>
                        {applications.length ?
                            applications.map((application, index) =>
                                <ApplicationItem key={index} application={application}
                                                 onClick={handleAttendAppointmentClick}
                                                 onCancel={handleCancelApplicationClick}/>
                            ) : (
                                <div>You don't have any applications</div>
                            )}
                    </div>
                </div>
            </div>
            <CustomModal {...modalProps} isLoading={applicationsIsLoading}>
                {modalProps.children}
            </CustomModal>
        </div>
    )
}
export {HomePage}
