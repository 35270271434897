import {useEffect} from "react";
import {Container} from "react-bootstrap";
import Application from "../../Models/Application/Application";
import Navigator from "../../Models/Navigator/Navigator";
import ReviewPermissions from "../components/ReviewPermissions/ReviewPermissions";
import {useNavigate} from "react-router-dom";
import useTranslate from "../../Hooks/useTranslate";
import {useAppSelector} from "../../State/hooks.ts";
import User from "../../Models/User/User.ts";
/** DO NOT REMOVE THE LINE BELOW **/
import "../../Utils/Vidyo/js/VidyoClient/22.5.1.0001/VidyoClient(22.5.1.0001).min.js"
import '../../Utils/Vidyo/css/VidyoClient.css'

/** DO NOT REMOVE THE LINE ABOVE **/

const VideoLobbyPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslate('translations');
    const application = useAppSelector(Application.selectApplication);
    const applicationId = useAppSelector(Navigator.selectApplicationId);
    const user = useAppSelector(User.selectUser);
    useEffect(() => {
        if (applicationId) {
            Application.getApplication(applicationId);
            Navigator.setApplicationId("");
        } else if (applicationId === undefined) {
            navigate("/");
        }
    }, [applicationId]);

    const handleOnProceedClick = () => {
        if (user && application?.isAppointmentDueNow && application.workflowId && application.videoSessionId) {
            navigate("/video-room");
        } else {
            console.log("missing", user, application?.isAppointmentDueNow, application?.workflowId, application?.videoSessionId)
        }
    }
    return (
        <>
            {application && (
                <>
                    {application.isAppointmentDueNow ? (
                        <ReviewPermissions onProceed={handleOnProceedClick}/>
                    ) : (
                        <Container className="m-auto mt-5">
                            <p>
                                {t("The video session cannot start because the appointment is not due until")}
                                <strong> {application.appointmentTime}</strong>
                            </p>
                            <p>{t("Please check your appointment details and try again later")}</p>
                        </Container>
                    )}
                </>
            )}
        </>
    );
};

export default VideoLobbyPage;
