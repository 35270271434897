import {StyleSheet} from "aphrodite";
import themeConstants from "../themes/themeConstants.ts";

export default StyleSheet.create({
    container: {
        // height: '100%',
        flexGrow: 1,
        width: '100%',
        backgroundColor: "gray",
        position: 'relative',
    },
    remoteVideoRendererContainer: {
        position: 'absolute',
        bottom: 0,
        left: 1,
        width: 150,
        maxWidth: '50%',
        height: 90,
        backgroundColor: "darkgray",
    },
    remoteVideoRenderer: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
    },
    localVideoRendererContainer: {
        width: '100%',
        height: '100%',
        border: '1px solid darkgray',
    },
    localVideoRenderer: {
        width: '100%',
        height: '100%',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '50%',
        height: 110,
        backgroundColor: "transparent",
        // border: '1px solid red',
        paddingRight: 10,
        paddingBottom: 10,
    },
    rendererButton: {
        // border: '1px solid rgb(162, 162, 162)',
        border: 'none',
        // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        boxShadow: 'none',
        opacity: 0.5,
        ':hover': {
            opacity: 1,
            // border: '1px solid rgb(162, 162, 162)',
            // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            // background: 'white',
            transition: 'all .2s'
        },
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: 35,
        // height: 70,
        width: 70,
        padding: 10,
        // marginRight: 10,
    },
    otpButton: {
        border: '1px solid rgb(162, 162, 162)',
        // border: 'none',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        // boxShadow: 'none',
        opacity: 0.7,
        ':hover': {
            opacity: 1,
            // border: '1px solid rgb(162, 162, 162)',
            // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            // background: 'white',
            transition: 'all .2s'
        },
        cursor: 'pointer',
        background: 'white',
        borderRadius: 35,
        height: 60,
        width: 60,
        padding: 10,
        marginRight: 10,
        color: themeConstants.colors.primary,
        fontWeight: 700,
    },
    footerToolbarButtonIcon: {
        width: '100%',
        height: '100%',
    },
    otpButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        // border: '1px solid blue'
    },
    footerToolbar: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: 'transparent',
        // position: 'absolute',
        // bottom: 5,
        color: themeConstants.colors.primary,
        // left: 0,
        // paddingLeft: 10,
    },
    footerToolbarButton: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: 35,
        minHeight: 50,
        height: 50,
        minWidth: 50,
        width: 50,
        padding: 10,
        marginRight: 10,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    footerToolbarButtonSmall: {
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        background: 'transparent',
        borderRadius: 35,
        height: 40,
        width: 40,
        padding: 7,
        marginBottom: 2,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    footerToolbarButtonEnabled: {
        backgroundColor: themeConstants.colors.primary,
        border: '1px solid rgb(162, 162, 162)',
        cursor: 'pointer',
        borderRadius: 35,
        minHeight: 50,
        height: 50,
        minWidth: 50,
        width: 50,
        padding: 10,
        marginRight: 10,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    settingsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    settingRow: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingTop: 5,
        paddingBottom: 5,
    },
})
