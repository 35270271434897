import {StyleSheet} from "aphrodite";

export default StyleSheet.create({
    pinInputField: {
        textAlign: "center",
        fontSize: 24,
        fontWeight: 700,
        maxWidth: 50,
    },
    pinInputContainer: {
        width: '100%',
        maxWidth: 500,
        display: "flex",
        justifyContent: "space-around",
    }

})
