import {Modal, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {CustomModalProps} from "./CustomModalProps.ts";
import useTranslate from "../../../Hooks/useTranslate.ts";


const CustomModal = (props: CustomModalProps) => {
    const {show, title, children, onConfirm, onCancel, onHide, cancelText, confirmText, backdrop, isLoading} = props;
    const {t} = useTranslate('translations');
    const handleConfirm = () => {
        if (onConfirm && typeof onConfirm === 'function') {
            onConfirm()
        }
    }
    const handleCancel = () => {
        if (onCancel && typeof onCancel === 'function') {
            onCancel()
        }
    }
    return (
        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={backdrop || (onHide ? true : 'static')}
            centered
        >
            <Modal.Header closeButton={!!onHide}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                {onCancel && <Button disabled={isLoading} variant={"secondary"}
                                     onClick={handleCancel}>{cancelText || t('Close')}</Button>}
                {onConfirm && <Button disabled={isLoading} onClick={handleConfirm}>
                    {isLoading ?
                        (<Spinner animation="border" role="status" variant="light"></Spinner>) :
                        (confirmText || t('Ok'))}
                </Button>}
            </Modal.Footer>
        </Modal>
    );
}
export {CustomModal}
export type {CustomModalProps}
