import {useAppSelector} from "../../State/hooks";
import {selectUserIsLoading} from "../../Models/User/userSlice.ts";
import React, {useState} from "react";
import User from "../../Models/User/User.ts";
import useTranslate from "../../Hooks/useTranslate.ts";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './styles.tsx'
import {css} from "aphrodite";
import {Card, Container, Spinner} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Logger from "../../Utils/Logger.ts";
import {Notify} from "../../Utils/Notify.ts";

const SignUpPage = () => {
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [passwordConfirm, setPasswordConfirm] = useState<string | null>(null);
    const [validated, setValidated] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string | null }>({});
    const userIsLoading = useAppSelector(selectUserIsLoading);
    const {t} = useTranslate('translations');
    const navigate = useNavigate();

    const validateFirstName = (name: string | null) => {
        if (!name || name.trim() === "") {
            return t("First name is required.");
        }
        return null;
    };

    const validateLastName = (name: string | null) => {
        if (!name || name.trim() === "") {
            return t("Last name is required.");
        }
        return null;
    };

    const validatePhoneNumber = (phone: string | null) => {
        if (!phone || !/^\+?[0-9\s\-()]{10,20}$/.test(phone)) {
            return t("Invalid phone number. Must be a valid format with 10-20 digits.");
        }
        return null;
    };

    const validateEmail = (email: string | null) => {
        if (!email ||
            !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
            return t("Invalid email address.");
        }
        return null;
    };

    const validatePassword = (password: string | null) => {
        if (!password || !/(?=.*[!@#$%^&*(),.?":{}|<>])/.test(password)) {
            return t("Password must contain at least one symbol.");
        }
        return null;
    };

    const validatePasswordConfirm = (password: string | null, confirm: string | null) => {
        if (password !== confirm) {
            return t("Passwords do not match.");
        }
        return null;
    };

    const validateField = (field: string, value: string | null) => {
        let error = null;
        switch (field) {
            case "firstName":
                error = validateFirstName(value);
                break;
            case "lastName":
                error = validateLastName(value);
                break;
            case "phoneNumber":
                error = validatePhoneNumber(value);
                break;
            case "email":
                error = validateEmail(value);
                break;
            case "password":
                error = validatePassword(value);
                break;
            case "passwordConfirm":
                error = validatePasswordConfirm(password, value);
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({...prevErrors, [field]: error}));
    };

    const handleFieldChange = (
        field: string,
        value: string | null,
        setState: React.Dispatch<React.SetStateAction<string | null>>
    ) => {
        setState(value);
        validateField(field, value);
    };

    const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const firstNameError = validateFirstName(firstName);
        const lastNameError = validateLastName(lastName);
        const phoneError = validatePhoneNumber(phoneNumber);
        const emailError = validateEmail(email);
        const passwordError = validatePassword(password);
        const confirmError = validatePasswordConfirm(password, passwordConfirm);

        const newErrors = {
            firstName: firstNameError,
            lastName: lastNameError,
            phoneNumber: phoneError,
            email: emailError,
            password: passwordError,
            passwordConfirm: confirmError,
        };

        setErrors(newErrors);

        if (Object.values(newErrors).every((error) => !error)) {
            try {
                if (firstName && lastName && phoneNumber && email && password) {
                    await User.register({
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        password,
                    });
                    navigate("/login");
                }
                setValidated(true);
            } catch (e) {
                if (e instanceof Error) {
                    Logger.error("SignUpPage@register() Exception: " + e.message);
                    Notify(t(e.message), "error", 5000);
                } else if (typeof e === "string") {
                    Logger.error("SignUpPage@register() Exception: ", e);
                    Notify(t(e), "error", 5000);
                }
            }
        } else {
            setValidated(false);
        }
    };

    return (
        <Container className={css(styles.container)}>
            <Card className={css(styles.card)}>
                <Form noValidate validated={validated} onSubmit={handleRegister}>
                    {/* First Name */}
                    <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label column={"lg"}>{t("First Name")}</Form.Label>
                        <Form.Control
                            type="text"
                            className={css(styles.input)}
                            placeholder={t("Enter First Name")}
                            value={firstName || ""}
                            readOnly={userIsLoading}
                            required
                            onChange={(e) =>
                                handleFieldChange("firstName", e.target.value, setFirstName)
                            }
                        />
                        {errors.firstName && (
                            <Form.Text className={css(styles.inputError)} muted>
                                {errors.firstName}
                            </Form.Text>
                        )}
                    </Form.Group>

                    {/* Last Name */}
                    <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label column={"lg"}>{t("Last Name")}</Form.Label>
                        <Form.Control
                            type="text"
                            className={css(styles.input)}
                            placeholder={t("Enter Last Name")}
                            value={lastName || ""}
                            readOnly={userIsLoading}
                            required
                            onChange={(e) =>
                                handleFieldChange("lastName", e.target.value, setLastName)
                            }
                        />
                        {errors.lastName && (
                            <Form.Text className={css(styles.inputError)} muted>
                                {errors.lastName}
                            </Form.Text>
                        )}
                    </Form.Group>

                    {/* Phone Number */}
                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                        <Form.Label column={"lg"}>{t("Mobile Phone Number")}</Form.Label>
                        <Form.Control
                            type="phone"
                            className={css(styles.input)}
                            placeholder={t("Enter Mobile Phone Number")}
                            value={phoneNumber || ""}
                            readOnly={userIsLoading}
                            required
                            onChange={(e) =>
                                handleFieldChange("phoneNumber", e.target.value, setPhoneNumber)
                            }
                        />
                        {errors.phoneNumber && (
                            <Form.Text className={css(styles.inputError)} muted>
                                {errors.phoneNumber}
                            </Form.Text>
                        )}
                    </Form.Group>

                    {/* Email */}
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label column={"lg"}>{t("Email")}</Form.Label>
                        <Form.Control
                            type="email"
                            className={css(styles.input)}
                            placeholder={t("Enter your email")}
                            value={email || ""}
                            readOnly={userIsLoading}
                            required
                            onChange={(e) => handleFieldChange("email", e.target.value, setEmail)}
                        />
                        {errors.email && (
                            <Form.Text className={css(styles.inputError)} muted>
                                {errors.email}
                            </Form.Text>
                        )}
                    </Form.Group>

                    {/* Password */}
                    <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label column={"lg"}>{t("Password")}</Form.Label>
                        <Form.Control
                            type="password"
                            className={css(styles.input)}
                            placeholder={t("Enter password")}
                            value={password || ""}
                            readOnly={userIsLoading}
                            required
                            onChange={(e) => handleFieldChange("password", e.target.value, setPassword)}
                            autoComplete={"new-password"}
                        />
                        {errors.password && (
                            <Form.Text className={css(styles.inputError)} muted>
                                {errors.password}
                            </Form.Text>
                        )}
                    </Form.Group>

                    {/* Confirm Password */}
                    <Form.Group className="mb-3" controlId="formPasswordConfirm">
                        <Form.Label column={"lg"}>{t("Confirm Password")}</Form.Label>
                        <Form.Control
                            type="password"
                            className={css(styles.input)}
                            placeholder={t("Confirm your password")}
                            value={passwordConfirm || ""}
                            readOnly={userIsLoading}
                            required
                            onChange={(e) =>
                                handleFieldChange("passwordConfirm", e.target.value, setPasswordConfirm)
                            }
                            autoComplete={"new-password"}
                        />
                        {errors.passwordConfirm && (
                            <Form.Text className={css(styles.inputError)} muted>
                                {errors.passwordConfirm}
                            </Form.Text>
                        )}
                    </Form.Group>

                    {/* Submit Button */}
                    <Form.Group className="mb-3 d-flex justify-content-center" controlId="formSubmit">
                        <Button
                            type={"submit"}
                            className={css(styles.loginButton)}
                            disabled={userIsLoading}
                        >
                            {userIsLoading ? (
                                <Spinner as="span" animation="border" role="status" aria-hidden="true"/>
                            ) : (
                                <div className={css(styles.buttonLabel)}>{t("Register")}</div>
                            )}
                        </Button>
                    </Form.Group>
                </Form>
                <div className={css(styles.linkContainer)}>
                    <Button onClick={() => navigate("/login")} variant={"link"}>
                        {t("Login")}
                    </Button>
                </div>
            </Card>
        </Container>
    );
};

export {SignUpPage}
