import {useEffect, useState} from "react";
import {Card, Container} from "react-bootstrap";
import QRCode from "react-qr-code";
import {useLocation, useNavigate} from "react-router-dom";
import useTranslate from "../../Hooks/useTranslate";
import {css} from "aphrodite";
import styles from "./styles";
import Navigator from "../../Models/Navigator/Navigator";
import {DeviceDetailsType, getDeviceDetails} from "../../Utils/Helpers.ts";
import Logger from "../../Utils/Logger.ts";


const AppointmentLandingPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslate('translations');
    const location = useLocation();
    const [isCompatibleBrowser, setIsCompatibleBrowser] = useState<boolean | null>(null);
    const [deviceDetails, setDeviceDetails] = useState<DeviceDetailsType | null>(null);
    const currentUrl = window.location.href;

    useEffect(() => {
        const checkMobileDevice = () => {
            const userAgent = navigator.userAgent;
            const deviceDetailsObj = getDeviceDetails(userAgent);
            setDeviceDetails(deviceDetailsObj);

            Logger.console("User Agent", userAgent)
            Logger.console("OS Details", deviceDetails)
            if (deviceDetailsObj.isMobile) {
                if (['Safari', 'Chrome'].includes(deviceDetailsObj.browser)) {
                    Navigator.setApplicationId(location.pathname.split("/")[2])
                    navigate("/video-lobby");
                    setIsCompatibleBrowser(true)
                } else {
                    setIsCompatibleBrowser(false)
                }
            }
        };

        checkMobileDevice();
    }, []);

    return (
        <Container className="d-flex justify-content-center align-items-center m-auto">
            {deviceDetails?.isMobile === false &&
                (<Card className={css(styles.card)}>
                    <Card.Body>
                        <Card.Title>{t("Scan this QR from a mobile device")}</Card.Title>
                        <QRCode value={currentUrl} size={200}/>
                    </Card.Body>
                </Card>)
            }
            {deviceDetails?.isMobile === true && isCompatibleBrowser === false &&
                (<div>
                    Incompatible Browser ({deviceDetails?.browser})
                    <div>
                        <a href={'googlechrome://navigate?url=' + currentUrl}>Open in Chrome</a>
                        {deviceDetails.os === 'iOS' && <a href={currentUrl}>Open in Safari</a>}
                    </div>
                </div>)}
        </Container>
    );
};

export default AppointmentLandingPage;
